import React, { Component } from "react";
//import Chart from "react-apexcharts";
import loadable from 'loadable-components';
const Chart = loadable(() => import('react-apexcharts'));
import PropTypes from 'prop-types';

import AtWork from "../../images/icon-at-work.svg";
import HomeWorking from "../../images/icon-home-working.svg";
import DayOff from "../../images/icon-day-off.svg";
import OffSick from "../../images/icon-off-sick.svg";

class AdminChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todayData: [],
      todayLocationCount: 0,
      todayHomeCount: 0,
      todaySickCount: 0,
      todayDayOffCount: 0,
      yestData: [],
      yestLocationCount: 0,
      yestHomeCount: 0,
      yestSickCount: 0,
      yestDayOffCount: 0,
      series: [
        {
          name: "Home Working",
          data: [0, 0]
        },
        {
          name: "On site",
          data: [0, 0]
        }
      ]
    };
  }

  apexOptions() {
    const options = {
      colors: ['#5c94f1', '#6bf1ac'],
      dataLabels: {
        enabled: false
      },
      grid: {
        strokeDashArray: 6,
      },
      markers: {
        size: 12,
        strokeWidth: 6,
        strokeColors: "#000000",
        strokeOpacity: 0.07,
        hover: {
          size: 11
        }
      },
      tooltip: {
        shared: false,
        //intersect: true,
        custom: (series) => {
          var locationCount, homeCount, sickCount, dayOffCount;
          // if dataPointIndex is 1, then it's today. Else if 0 it equals yesterday.
          locationCount = series.dataPointIndex === 1 ? this.state.todayLocationCount : this.state.yestLocationCount;
          homeCount = series.dataPointIndex === 1 ? this.state.todayHomeCount : this.state.yestHomeCount;
          sickCount = series.dataPointIndex === 1 ? this.state.todaySickCount : this.state.yestSickCount;
          dayOffCount = series.dataPointIndex === 1 ? this.state.todayDayOffCount : this.state.yestDayOffCount;
          return '<div class="arrow_box p-4">' +
            '<div class="mb-1"><img src="' + AtWork + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + locationCount + '</span> Location' + '</div>' +
            '<div><img src="' + HomeWorking + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + homeCount + '</span> Home working' + '</div>' +
            '<div><img src="' + OffSick + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + sickCount + '</span> Sick' + '</div>' +
            '<div><img src="' + DayOff + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + dayOffCount + '</span> Day off' + '</div>' +
            '</div>'
        }
      },
      stroke: {
        curve: 'smooth',
      },
      chart: {
        selection: {
          enabled: false
        },
        id: "admin-chart",
        toolbar: {
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        }
      },
      yaxis: {
        labels: {
          "formatter": function (val) {
              if(typeof val !== "undefined" && (val - Math.floor(val)) === 0) { // only show whole numbers
                return val.toFixed()
              }
            }
        }
      },
      xaxis: {
        labels: {
          style: {
            cssClass: 'xaxis-label'
          },
          offsetY: 5
        },
        axisBorder: {
          show: false
        },
        tooltip: {
          enabled: false,
        },
        categories: ["Yesterday", "Today"]
      }
    }
    return options;
  }

  componentDidUpdate() {
    const todayData = this.props.todayData;
    const yestData = this.props.yestData;
    const todayStateString = JSON.stringify(this.state.todayData);
    const todayPropsString = JSON.stringify(todayData);
    const yestStateString = JSON.stringify(this.state.yestData);
    const yestPropsString = JSON.stringify(yestData);

    // compare array, if different update state
    if (todayStateString != todayPropsString || yestStateString != yestPropsString) {
      var series = this.state.series;

      // TODAY
      // location
      const todayLocationCount = todayData.filter((cur) => (
        cur.type === "location"
      )).length;

      // home working
      const todayHomeCount = todayData.filter((cur) => (
        cur.type === "organisation"
      )).length;

      // off sick
      const todaySickCount = todayData.filter((cur) => (
        cur.type === "sick" || cur.type === "sick-covid-confirmed" || cur.type === "sick-covid-symptoms" || cur.type === "unable-to-work"
      )).length;

      // day off
      const todayDayOffCount = todayData.filter((cur) => (
        cur.type === "day-off"
      )).length;
      
      // today counts
      series[0].data[1] = todayHomeCount;
      series[1].data[1] = todayLocationCount;

      // YESTERDAY
      // location
      const yestLocationCount = yestData.filter((cur) => (
        cur.type === "location"
      )).length;

      // home working
      const yestHomeCount = yestData.filter((cur) => (
        cur.type === "organisation"
      )).length;

      // off sick
      const yestSickCount = yestData.filter((cur) => (
        cur.type === "sick" || cur.type === "sick-covid-confirmed" || cur.type === "sick-covid-symptoms" || cur.type === "unable-to-work"
      )).length;

      // day off
      const yestDayOffCount = yestData.filter((cur) => (
        cur.type === "day-off"
      )).length;
      
      // yesterday counts
      series[0].data[0] = yestHomeCount;
      series[1].data[0] = yestLocationCount;

      this.setState({
        todayData, todayLocationCount, todayHomeCount, todaySickCount, todayDayOffCount,
        yestData, yestLocationCount, yestHomeCount, yestSickCount, yestDayOffCount,
        series: series }, () => console.log(this.state))
    }
    console.log("Exec...");
    console.log(series);
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.apexOptions()}
              series={this.state.series}
              type="area"
              width="100%"
              height="275"
            />
          </div>
        </div>
      </div>
    );
  }
}

AdminChart.propTypes = {
  todayData: PropTypes.array,
  yestData: PropTypes.array
}

export default AdminChart;
