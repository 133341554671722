import React, { Component } from "react";
//import Chart from "react-apexcharts";
import loadable from 'loadable-components';
const Chart = loadable(() => import('react-apexcharts'));
import PropTypes from 'prop-types';

// Mood Icons
import GreatIcon from "../../images/icon-great.svg";
import GoodIcon from "../../images/icon-good.svg";
import CopingIcon from "../../images/icon-coping.svg";
import StrugglingIcon from "../../images/icon-struggling.svg";
import AwfulIcon from "../../images/icon-awful.svg";

class AdminChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todayData: [],
      todayGreatCount: 0,
      todayGoodCount: 0,
      todayCopingCount: 0,
      todayStrugglingCount: 0,
      todayAwfulCount: 0,
      todayTotal: 0,
      yestData: [],
      yestGreatCount: 0,
      yestGoodCount: 0,
      yestCopingCount: 0,
      yestStrugglingCount: 0,
      yestAwfulCount: 0,
      yestTotal: 0,
      series: [
        {
          name: "Wellbeing",
          data: [0, 0]
        }
      ]
    };
  }

  apexOptions() {
    const options = {
      colors: ['#5c94f1'],
      dataLabels: {
        enabled: false
      },
      grid: {
        strokeDashArray: 4,
      },
      markers: {
        size: 12,
        strokeWidth: 6,
        fillOpacity: 0,
        strokeColors: "#000000",
        strokeOpacity: 0.07,
        hover: {
          size: 11
        }
      },
      tooltip: {
        shared: false,
        custom: (series) => {
          var greatCount, goodCount, copingCount, strugglingCount, awfulCount;
          const dataIndex = series.dataPointIndex;
          const value = series.series[0][dataIndex];
          // if dataPointIndex is 1, then it's today. Else if 0 it equals yesterday.
          greatCount = series.dataPointIndex === 1 ? this.state.todayGreatCount : this.state.yestGreatCount;
          goodCount = series.dataPointIndex === 1 ? this.state.todayGoodCount : this.state.yestGoodCount;
          copingCount = series.dataPointIndex === 1 ? this.state.todayCopingCount : this.state.yestCopingCount;
          strugglingCount = series.dataPointIndex === 1 ? this.state.todayStrugglingCount : this.state.yestStrugglingCount;
          awfulCount = series.dataPointIndex === 1 ? this.state.todayAwfulCount : this.state.yestAwfulCount;
          return '<div class="arrow_box p-4">' +
            '<div class="mb-2">' + value + '%</div>' +
            '<div class="mb-1"><img src="' + GreatIcon + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + greatCount + '</span> Great' + '</div>' +
            '<div class="mb-1"><img src="' + GoodIcon + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + goodCount + '</span> Good' + '</div>' +
            '<div class="mb-1"><img src="' + CopingIcon + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + copingCount + '</span> Coping' + '</div>' +
            '<div class="mb-1"><img src="' + StrugglingIcon + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + strugglingCount + '</span> Struggling' + '</div>' +
            '<div class="mb-1"><img src="' + AwfulIcon + '" class="inline-block mr-2 h-6" /><span class="font-bold text-gunmetal-500">' + awfulCount + '</span> Awful' + '</div>' +
            '</div>'
        }
      },
      stroke: {
        curve: 'smooth',
      },
      chart: {
        id: "admin-chart",
        toolbar: {
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: (val) => { return val + "%" },
        },
      },
      xaxis: {
        labels: {
          style: {
            cssClass: 'xaxis-label'
          },
          offsetY: 5
        },
        axisBorder: {
          show: false
        },
        tooltip: {
          enabled: false,
        },
        categories: ["Yesterday", "Today"]
      }
    }
    return options;
  }

  componentDidUpdate() {
    const todayData = this.props.todayData;
    const yestData = this.props.yestData;
    const todayStateString = JSON.stringify(this.state.todayData);
    const todayPropsString = JSON.stringify(todayData);
    const yestStateString = JSON.stringify(this.state.yestData);
    const yestPropsString = JSON.stringify(yestData);

    // compare array, if different update state
    if (todayStateString != todayPropsString || yestStateString != yestPropsString) {
      var series = this.state.series;

      // TODAY
      if (todayData.length === 0) {
        series[0].data[1] = 0;
      }
      else {
        const todayGreatCount = todayData.filter((cur) => (
          cur.mood && cur.mood.label === "great"
        )).length;
        const todayGoodCount = todayData.filter((cur) => (
          cur.mood && cur.mood.label === "good"
        )).length;
        const todayCopingCount = todayData.filter((cur) => (
          cur.mood && cur.mood.label === "coping"
        )).length;
        const todayStrugglingCount = todayData.filter((cur) => (
          cur.mood && cur.mood.label === "struggling"
        )).length;
        const todayAwfulCount = todayData.filter((cur) => (
          cur.mood && cur.mood.label === "awful"
        )).length;

        let todayTotal = (todayGreatCount + todayGoodCount + todayCopingCount + todayStrugglingCount + todayAwfulCount);
        let todayGreatCalc = todayGreatCount;
        let todayGoodCalc = todayGoodCount * 0.75;
        let todayCopingCalc = todayCopingCount * 0.5;
        let todayStrugglingCalc = todayStrugglingCount * 0.25;
        let todayAwfulCalc = todayAwfulCount * 0;
        let todayTotalPerc = (todayGreatCalc + todayGoodCalc + todayCopingCalc + todayStrugglingCalc + todayAwfulCalc) / todayTotal * 100;
        series[0].data[1] = (todayTotal == 0 ? 0 : todayTotalPerc);
        this.setState({ todayGreatCount, todayGoodCount, todayCopingCount, todayStrugglingCount, todayAwfulCount, todayTotal })
      }

      // YESTERDAY
      if (yestData.length === 0) {
        series[0].data[0] = 0;
      }
      else {
        var yestGreatCount = yestData.filter((cur) => (
          cur.mood && cur.mood.label === "great"
        )).length;
        var yestGoodCount = yestData.filter((cur) => (
          cur.mood && cur.mood.label === "good"
        )).length;
        var yestCopingCount = yestData.filter((cur) => (
          cur.mood && cur.mood.label === "coping"
        )).length;
        var yestStrugglingCount = yestData.filter((cur) => (
          cur.mood && cur.mood.label === "struggling"
        )).length;
        var yestAwfulCount = yestData.filter((cur) => (
          cur.mood && cur.mood.label === "awful"
        )).length;

        let yestTotal = (yestGreatCount + yestGoodCount + yestCopingCount + yestStrugglingCount + yestAwfulCount);
        let yestGreatCalc = yestGreatCount;
        let yestGoodCalc = yestGoodCount * 0.75;
        let yestCopingCalc = yestCopingCount * 0.5;
        let yestStrugglingCalc = yestStrugglingCount * 0.25;
        let yestAwfulCalc = yestAwfulCount * 0;
        let yestCalcTotal = (yestGreatCalc + yestGoodCalc + yestCopingCalc + yestStrugglingCalc + yestAwfulCalc)
        let yestTotalPerc = (yestCalcTotal / yestTotal * 100);
        series[0].data[0] = (yestTotal == 0 ? 0 : yestTotalPerc);
        this.setState({ yestGreatCount, yestGoodCount, yestCopingCount, yestStrugglingCount, yestAwfulCount, yestTotal })
      }

      this.setState({ todayData, yestData, series })

    }

  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            {this.state.todayTotal > 4 || this.state.yestTotal > 4 ? (
              <Chart
                options={this.apexOptions()}
                series={this.state.series}
                type="area"
                width="100%"
                height="250"
              />
            ) : <div className="text-center bg-gray-200 py-24 text-gray-500">Not enough wellbeing data...</div>}
          </div>
        </div>
      </div>
    );
  }
}

AdminChart.propTypes = {
  todayData: PropTypes.array,
  yestData: PropTypes.array
}

export default AdminChart;
