import React from "react";
import AppLayout from "../../views/containers/appLayout";
import AppLayoutContainer from "../../views/containers/AppLayoutContainer";
import Title from "../../views/components/title";
import LocationCard from "../../views/components/locationCard";
import Layout from "../../views/components/layout";
import AdminNav from "../../views/components/adminNav";
import LocationModalContent from "../../views/components/locationModalContent";
import Modal from 'react-modal';
import { getTokens, getProfile } from "../../utils/auth";
const axios = require('axios').default;

Modal.setAppElement('#___gatsby');

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationData: [],
      orgRefData: [],
      updateData: {},
      showModal: false,
      modalType: 'add'
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true, modalType: 'add' });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleUpdateClick(location) {
    this.setState({ showModal: true, updateData: location, modalType: 'update' }, () => console.log(this.state));
  }

  onFormSuccess() {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: process.env.ETHO_API + '/locations?populate=admins',
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        console.log(res.data);
        this.setState({ locationData: res.data })
      })
      .catch((err) => {
        console.log(err)
      })

    axios({
      method: 'get',
      url: process.env.ETHO_API + '/organisations',
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        this.setState({ orgRefData: res.data })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <>
        <AdminNav />

        <Layout>
          <AppLayout>
            <Title border={true}>Locations</Title>
            <AppLayoutContainer>

              <Modal
                isOpen={this.state.showModal}
                contentLabel="Modal"
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={true}
                className="modal modal-sm"
                overlayClassName="modal-overlay"
              >
                <span onClick={this.handleCloseModal} className="modal-close-icon"><svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-10 h-10"><path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span>
                <LocationModalContent data={this.state.updateData} modalType={this.state.modalType} />
              </Modal>

              <div className="flex justify-end">
                { !(getProfile().roles || []).includes("etho-super-user") ? <></> : (
                  <button onClick={this.handleOpenModal} className="inline-flex items-center cursor-pointer border-2 rounded-md border-blue-500 text-blue-500 hover:bg-blue-100 font-semibold pl-4 pr-5 py-2">
                      <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6 mr-2"><path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                      Add Location
                  </button>
                )} 
              </div>

              <div className="flex flex-wrap -mx-3">
                {this.state.locationData.map((location, i) => {
                  return (
                    <div key={i} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3">
                      <LocationCard data={location} orgRefData={this.state.orgRefData} onUpdateClick={(location) => this.handleUpdateClick(location)} />
                    </div>
                  )
                })}
              </div>

            </AppLayoutContainer>
          </AppLayout>
        </Layout>
      </>
    );
  }
}

export default Locations;
