import React from "react";
import AppLayout from "../../views/containers/appLayout";
import AppLayoutContainer from "../../views/containers/AppLayoutContainer";
import Promise from "promise";
import Title from "../../views/components/title";
import Layout from "../../views/components/layout";
import { navigate } from "gatsby";
import Select from "react-select";
import Button from "../../views/components/button";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getFirstName } from "../../utils/helpers";
import { getTokens, getProfile } from "../../utils/auth";
const axios = require("axios").default;

import IconSelfIsolating from "../../images/icon-self-isolating.svg";
import IconAvailable from "../../images/icon-available.svg";
import { toast } from "react-toastify";

class CheckIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tab: 0,
      selectValue: "",
      selfIsolating: false,
      available: false,
      user: {},
      locations: [],
      organisations: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  fetchData = (URL) => {
    return axios({
      method: "get",
      url: URL,
      headers: { Authorization: "Bearer " + getTokens().accessToken },
    })
      .then(function (response) {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch(function (error) {
        return { success: false, message: error };
      });
  };

  componentDidMount() {
    Promise.all(
      [
        process.env.ETHO_API + "/me",
        process.env.ETHO_API + "/locations?populate=organisations", // location reference data
        process.env.ETHO_API + "/organisations", // organisation reference data
        process.env.ETHO_API +
          "/me/attendances?sort[check_in_time]=-1&page[limit]=1", // latest attendance for user
      ].map(this.fetchData)
    )
      .then((resp) => {
        // if we're already checked in today redirect to checkout
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        today = mm + "-" + dd + "-" + yyyy;
        if (
          resp[3].data.length == 1 &&
          new Date(resp[3].data[0].check_in_time) >= new Date(today) &&
          typeof resp[3].data[0].check_out_time === "undefined"
        ) {
          navigate("/app/attendance/checkout");
          return;
        }
        // get default for self-isolating
        let selfIsolating =
          resp[3].data.length == 1 ? resp[3].data[0].self_isolating : false;
        this.setState({
          user: resp[0].data,
          locations: resp[1].data,
          organisations: resp[2].data,
          selfIsolating: selfIsolating,
          loading: false,
        });
      })
      .catch((e) => console.error(e));
  }

  getLabelValue = (props) => {
    return props.map((el) => {
      return { value: el._id, label: el.name };
    });
  };

  getLabelValueLocations = (props) => {
    let returnArg = [];
    props.forEach((prop) => {
      prop.organisations.forEach((org) => {
        let label =
          prop.organisations.length > 1
            ? `${prop.name} (${org.name})`
            : prop.name;
        returnArg.push({
          value: prop._id + org._id,
          label: label,
          locationID: prop._id,
          orgID: org._id,
        });
      });
    });
    return returnArg;
  };

  handleSelectChangeLocations = (e) => {
    this.setState({ selectValue: e });
  };

  handleSelectChange = (e) => {
    this.setState({ selectValue: e.value });
  };

  handleCheckboxChange = (e, key) => {
    const value = e.target.checked;
    if (key === "selfIsolating") {
      this.setState({ selfIsolating: value }, () => console.log(this.state));
    } else if (key === "available") {
      this.setState({ available: value }, () => console.log(this.state));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const select = this.state.selectValue;
    if (select === "") {
      toast.error("Please select from one of the dropdowns");
      return;
    }

    new Promise((resolve, reject) => {
      // Location selected
      if (this.state.tab == 0) {
        resolve({
          type: "location",
          locationName: select.label,
          location: select.locationID,
          organisation: select.orgID,
        });
      }

      // Home working
      else if (this.state.tab == 1) {
        resolve({
          type: "organisation",
          organisation: select,
          self_isolating: this.state.selfIsolating,
        });
      }

      // Not working
      if (this.state.tab == 2) {
        // get user primary organisation
        axios({
          method: "get",
          url: process.env.ETHO_API + "/me",
          headers: { Authorization: "Bearer " + getTokens().accessToken },
        })
          .then((res) => {
            const primaryOrganisation = res.data.primary_organisation;
            if (!primaryOrganisation) {
              throw new Error("No primary organisation for the user");
            }
            resolve({
              type: select,
              self_isolating: this.state.selfIsolating,
              available: this.state.available,
              organisation: primaryOrganisation,
            });
          })
          .catch((err) => {
            reject(err);
          });
      }
    })
      .then((postData) => {
        axios({
          method: "post",
          url: process.env.ETHO_API + "/me/attendances",
          data: postData,
          headers: { Authorization: "Bearer " + getTokens().accessToken },
        }).then(() => {
          // if home working, go straight to wellbeing
          if (this.state.tab == 2) {
            navigate("/app/attendance/wellbeing");
          } else {
            navigate("/app/attendance/checkout");
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    //navigate('/app/attendance/checkout')
    // post to me/attendances
  };

  render() {
    let user = getProfile();
    let name = user.name ? getFirstName(user.name) : "";
    const otherOptions = [
      { label: "Day Off", value: "day-off" },
      { label: "Off Sick", value: "sick" },
      { label: "Off Sick - Covid Confirmed", value: "sick-covid-confirmed" },
      { label: "Off Sick - Sick Covid Symptoms", value: "sick-covid-symptoms" },
      {
        label: "Unable to work - Caring responsibilities",
        value: "unable-to-work",
      },
    ];

    return (
      <Layout>
        <AppLayout>
          {this.state.loading ? (
            <><p className="text-center p-4">Loading...</p></>
          ) : (
            <>
              <Title>Hi {name}</Title>
              <AppLayoutContainer>
                <div className="flex justify-center">
                  <div className="md:shadow-md bg-white rounded-lg w-full sm:w-88 pb-8 mb-10">
                    <span className="block p-5 text-center text-3xl font-light">
                      Where are you today?
                    </span>

                    <Tabs onSelect={(index) => this.setState({ tab: index })}>
                      <TabList>
                        <Tab>At location</Tab>
                        <Tab>Home working</Tab>
                        <Tab>Not working</Tab>
                      </TabList>

                      <TabPanel>
                        <Select
                          placeholder={"Select Location"}
                          options={this.getLabelValueLocations(
                            this.state.locations
                          )}
                          onChange={(event) =>
                            this.handleSelectChangeLocations(event)
                          }
                        />
                      </TabPanel>

                      <TabPanel>
                        <Select
                          placeholder={"Select Organisation"}
                          options={this.getLabelValue(this.state.organisations)}
                          onChange={(event) => this.handleSelectChange(event)}
                        />
                        <hr className="mt-6 mb-4" />
                        <span className="block text-center text-xs text-gray-500 mb-2">
                          Optional
                        </span>

                        <div className="flex justify-between items-center flex-wrap flex-col">
                          <img src={IconSelfIsolating} className="mb-1" />
                          <span className="block text-center font-bold mb-1">
                            Self isolating
                          </span>
                          <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4"
                            onChange={(e) =>
                              this.handleCheckboxChange(e, "selfIsolating")
                            }
                            defaultChecked={this.state.selfIsolating}
                          />
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <Select
                          options={otherOptions}
                          onChange={(event) => this.handleSelectChange(event)}
                        />
                        <hr className="mt-6 mb-4" />
                        <span className="block text-center text-xs text-gray-500 mb-2">
                          Optional
                        </span>
                        <div className="flex flex-wrap justify-between">
                          <div className="w-1/2">
                            <img
                              src={IconSelfIsolating}
                              className="mb-1 mx-auto"
                            />
                            <span className="block text-center font-bold mb-1">
                              Self isolating
                            </span>
                            <div className="flex justify-center">
                              <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                onChange={(e) =>
                                  this.handleCheckboxChange(e, "selfIsolating")
                                }
                                defaultChecked={this.state.selfIsolating}
                              />
                            </div>
                          </div>
                          <div className="w-1/2">
                            <img src={IconAvailable} className="mb-1 mx-auto" />
                            <span className="block text-center font-bold">
                              Available
                            </span>
                            <span className="block text-center text-sm text-gray-400 mb-1">
                              (If needed)
                            </span>
                            <div className="flex justify-center">
                              <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                onChange={(e) =>
                                  this.handleCheckboxChange(e, "available")
                                }
                                defaultChecked={this.state.available}
                              />
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>

                    <div className="px-8">
                      <Button
                        onClick={this.handleSubmit}
                        styleType="checkIn"
                        className=""
                      >
                        Check In
                      </Button>
                    </div>
                  </div>
                </div>
              </AppLayoutContainer>
            </>
          )}
        </AppLayout>
      </Layout>
    );
  }
}

export default CheckIn;
