import React from "react";
import PropTypes from "prop-types";
import CardAdmin from "../components/cardAdmin";
import Section from "../components/cardAdminSection";
import UpdateBtn from "../components/adminButtonUpdate";
import Select from "react-select";
import { toast } from 'react-toastify';
import { getTokens } from "../../utils/auth";
const axios = require('axios').default;

class OrganisationCard extends React.Component {
  state = {
    parentNetworkSelected: [],
    networkSelected: [],
    orgSelected: [],
    memberSelected: [],
    edited: false
  }

  componentDidMount() {
    this.getSelectData('networks', 'networksData', 'networkSelected');
    this.getSelectDataParentNetworks();
    this.getSelectData('organisations', 'orgData', 'orgSelected');
  }

  componentDidUpdate() {
    // We set 'edited' to true to stop componentDidUpdate resetting state with our original data
    if (this.state.edited === true) return;
    this.getSelectData('networks', 'networksData', 'networkSelected');
    this.getSelectDataParentNetworks();
    this.getSelectData('organisations', 'orgData', 'orgSelected');
  }

  getSelectData(endpoint, data, selectedState) {
    const selectedIDs = this.props.data[endpoint];
    const elements = this.props[data];
    // get label & value of network
    let currentValues = [];
    elements.forEach(element => {
      selectedIDs.forEach(ID => {
        if (element._id === ID) {
          currentValues.push({ label: element.name, value: ID });
        }
      })
    });
    // Workaround. If there is a difference between the 2, then we update state
    if (this.state[selectedState].toString() != currentValues.toString()) {
      this.setState({ [selectedState]: currentValues }), console.log(this.state);
    }
  }

  getSelectDataParentNetworks() {
    const allNetworks = this.props.networksData;
    const networkID = this.props.data._id;
    let parentNetworks = allNetworks.filter((cur) => (
      cur.networks.includes(networkID)
    ));
    const selectedIDs = parentNetworks.map((network) => (
      network._id
    ));
    // get label & value of network
    let currentValues = [];
    allNetworks.forEach(network => {
      selectedIDs.forEach(ID => {
        if (network._id === ID) {
          currentValues.push({ label: network.name, value: ID });
        }
      })
    });
    // Workaround. If there is a difference between the 2, then we update state
    if (this.state.parentNetworkSelected.toString() != currentValues.toString()) {
      this.setState({ parentNetworkSelected: currentValues, originalParentNetworksSelected: currentValues }), console.log(this.state);
    }
  }

  handleSelectChange(event, endpoint, selectedState, ID) {
    let selectedIDs = [];
    // if user empties the input
    if (event === null) {
      this.setState({ [selectedState]: [], edited: true });
    }
    else {
      this.setState({ [selectedState]: event, edited: true }, console.log(this.state.selected));
      // push input event data to empty array
      event.forEach(element => {
        selectedIDs.push(element.value);
      });
    }
    axios({
      method: 'patch',
      url: process.env.ETHO_API + '/networks/' + ID,
      data: { [endpoint]: selectedIDs },
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then(() => {
        toast.success("Successfully updated network");
        setTimeout(() => { window.location.reload(); }, 1500); 
      })
      .catch((err) => {
        toast.error(err);
      })
  }

  handleSelectChangeParentNetwork(event) {
    // compare parentNetworkSelected against originalParentNetworksSelected
    var originalIDs = (this.state.originalParentNetworksSelected ?? []).map((cur) => cur.value);
    var newIDs = (event ?? []).map((cur) => cur.value);
    let addedIDs = newIDs.filter((cur) => (
      !originalIDs.includes(cur)
    ));
    let removedIDs = originalIDs.filter((cur) => (
      !newIDs.includes(cur)
    ));

    // if user empties the input
    if (event === null) {
      this.setState({ parentNetworkSelected: [], edited: true });
    }
    else {
      this.setState({ parentNetworkSelected: event, edited: true });
    }

    if (addedIDs && addedIDs.length > 0) {
      // push input event data to every parent network
      addedIDs.forEach(parentID => {
        axios({
          method: 'get',
          url: process.env.ETHO_API + '/networks/' + parentID,
          headers: { "Authorization": "Bearer " + getTokens().accessToken }
        })
          .then((res) => {
            let networks = res.data.networks;
            networks.push(this.props.data._id);
            axios({
              method: 'patch',
              url: process.env.ETHO_API + '/networks/' + parentID,
              data: { networks: networks },
              headers: { "Authorization": "Bearer " + getTokens().accessToken }
            })
              .then(() => {
                toast.success("Successfully updated network");
                setTimeout(() => { window.location.reload(); }, 1500); 
              })
          })
          .catch((err) => {
            toast.error(err);
          });
      });
    }

    if (removedIDs && removedIDs.length > 0) {
      removedIDs.forEach(parentID => {
        axios({
          method: 'get',
          url: process.env.ETHO_API + '/networks/' + parentID,
          headers: { "Authorization": "Bearer " + getTokens().accessToken }
        })
          .then((res) => {
            let networks = res.data.networks;
            let updatedNetworks = networks.filter((cur) => (
              cur != this.props.data._id
          ));
          console.log(updatedNetworks);
            axios({
              method: 'patch',
              url: process.env.ETHO_API + '/networks/' + parentID,
              data: { networks: updatedNetworks },
              headers: { "Authorization": "Bearer " + getTokens().accessToken }
            })
              .then(() => {
                toast.success("Successfully updated network");
                setTimeout(() => { window.location.reload(); }, 1500); 
              })
          })
          .catch((err) => {
            toast.error(err);
          });
      });
  }
}

getLabelValue = (props) => {
  return props.map((el) => { return { value: el._id, label: el.name } });
};

render() {
  const data = this.props.data;

  return (
    <CardAdmin>

      <Section border={false}>
        <UpdateBtn onClick={() => this.props.onUpdateClick(data)} />
      </Section>

      <Section>
        {data.logo && (
          <img src={data.logo} className="mx-auto mt-4 mb-6 h-8" alt="logo" />
        )}

        <span className="block font-bold mb-1">{data.name}</span>

        <div className="text-sm">

          {data.admins && (
            <>
              <span className="block text-xs font-bold">Admins</span>
              {data.admins.map((admin, i) => (
                <span key={i} className="block">{admin.name}</span>
              ))}
            </>
          )}
        </div>

      </Section>

      <Section title="Connected Networks">
        <label className="text-xs">Part of:</label>
        <Select className="mb-3" options={this.getLabelValue(this.props.networksData)} value={this.state.parentNetworkSelected} onChange={(event) => this.handleSelectChangeParentNetwork(event)} isMulti={true} isClearable={false} />

        <label className="text-xs">Made up of:</label>
        <Select options={this.getLabelValue(this.props.networksData)} value={this.state.networkSelected} onChange={(event) => this.handleSelectChange(event, 'networks', 'networkSelected', data._id)} isMulti={true} isClearable={false} />
      </Section>

      <Section title="Connected Organisations">
        <Select options={this.getLabelValue(this.props.orgData)} value={this.state.orgSelected} onChange={(event) => this.handleSelectChange(event, 'organisations', 'orgSelected', data._id)} isMulti={true} isClearable={false} />
      </Section>

    </CardAdmin >
  );
}
}

OrganisationCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  networksData: PropTypes.array,
  orgData: PropTypes.array,
  membersData: PropTypes.array,
  onUpdateClick: PropTypes.func
};

export default OrganisationCard;
