import React from "react";
import PropTypes from "prop-types";
import CardAdmin from "../components/cardAdmin";
import Section from "../components/cardAdminSection";
import UpdateBtn from "../components/adminButtonUpdate";
import Select from "react-select";
import { toast } from 'react-toastify';
import { getTokens } from "../../utils/auth";
const axios = require('axios').default;

class OrganisationCard extends React.Component {
  state = {
    networkSelected: [],
    locationSelected: [],
    memberSelected: [],
    edited: false
  }

  componentDidMount() {
    this.getSelectData('networks', 'networksData', 'networkSelected');
    this.getSelectData('locations', 'locationsData', 'locationSelected');
    this.getSelectData('members', 'membersData', 'memberSelected');
  }

  componentDidUpdate() {
    // We set 'edited' to true to stop componentDidUpdate resetting state with our original data
    if (this.state.edited === true) return;
    this.getSelectData('networks', 'networksData', 'networkSelected');
    this.getSelectData('locations', 'locationsData', 'locationSelected');
    this.getSelectData('members', 'membersData', 'memberSelected');
  }

  /* Begin functionality for Networks, Locations & Users */
  getSelectData(endpoint, data, selectedState) {
    const selectedIDs = this.props.data[endpoint];
    const elements = this.props[data]
    // get label & value of network
    let currentValues = [];
    elements.forEach(element => {
      selectedIDs.forEach(ID => {
        if (element._id === ID) {
          currentValues.push({ label: element.name, value: ID });
        }
      })
    });
    // Workaround. If there is a difference between the 2, then we update state
    if (this.state[selectedState].toString() !== currentValues.toString()) {
      this.setState({ [selectedState]: currentValues }), console.log(this.state);
    }
  }

  handleSelectChange(event, endpoint, selectedState, ID) {
    let selectedIDs = [];
    // if user empties the input
    if (event === null) {
      this.setState({ [selectedState]: [], edited: true });
    }
    else {
      this.setState({ [selectedState]: event, edited: true }, console.log(this.state.selected));
      // push input event data to empty array
      event.forEach(element => {
        selectedIDs.push(element.value);
      });
    }
    axios({
      method: 'patch',
      url: process.env.ETHO_API + '/organisations/' + ID,
      data: { [endpoint]: selectedIDs },
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        toast.success("Successfully updated");
        console.log(res.data);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err)
      })
  }

  getLabelValue = (props) => {
    return props.map((el) => { return { value: el._id, label: el.name } });
  };

  render() {
    const data = this.props.data;
    const address = data.address;
    if (address) {
      ['loc', '_id', 'updatedAt', 'createdAt'].forEach(prop => {
        delete address[prop]
      });
    }

    return (
      <CardAdmin>

        <Section border={false}>
          <UpdateBtn onClick={() => this.props.onUpdateClick(data)} />
        </Section>

        <Section>
          {data.logo && (
            <img src={data.logo} className="mx-auto mt-4 mb-6 h-8" alt="logo" />
          )}

          <span className="block font-bold mb-1">{data.name}</span>

          <div className="text-sm">
            {data.address && (
              <div className="mb-4">
                {Object.keys(address).map((key, index) => (
                  <span key={index} className="block whitespace-pre-line">{address[key]}</span>
                ))
                }
              </div>
            )}

            {data.admins && (
              <>
                <span className="block text-xs font-bold">Admins</span>
                {data.admins.map((admin, i) => (
                  <span key={i} className="block">{admin.name}</span>
                ))}
              </>
            )}
          </div>

        </Section>

        <Section title="Connected Networks">
          <Select options={this.getLabelValue(this.props.networksData)} value={this.state.networkSelected} onChange={(event) => this.handleSelectChange(event, 'networks', 'networkSelected', data._id)} isMulti={true} isClearable={false} />
        </Section>

        <Section title="Locations">
          <Select options={this.getLabelValue(this.props.locationsData)} value={this.state.locationSelected} onChange={(event) => this.handleSelectChange(event, 'locations', 'locationSelected', data._id)} isMulti={true} isClearable={false} />
        </Section>

        <Section title="Users">
          <Select options={this.getLabelValue(this.props.membersData)} value={this.state.memberSelected} onChange={(event) => this.handleSelectChange(event, 'members', 'memberSelected', data._id)} isMulti={true} isClearable={false} />
        </Section>

      </CardAdmin >
    );
  }
}

OrganisationCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  networksData: PropTypes.array,
  locationsData: PropTypes.array,
  membersData: PropTypes.array,
  onUpdateClick: PropTypes.func
};

export default OrganisationCard;
