import React from "react";
import AppLayout from "../../views/containers/appLayout";
import AppLayoutContainer from "../../views/containers/AppLayoutContainer";
import Title from "../../views/components/title";
import UserCard from "../../views/components/userCard";
import Layout from "../../views/components/layout";
import AdminNav from "../../views/components/adminNav";
import Select from "react-select";
import Modal from 'react-modal';
import UserModalContent from "../../views/components/userModalContent";
import { getProfile, getTokens } from "../../utils/auth";
import Can from "../../utils/can";
import _ from 'lodash';
const axios = require('axios').default;

Modal.setAppElement('#___gatsby');

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userData: [],
      updateData: {},
      modalType: 'add'
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  getUsers() {
    axios({
      method: 'get',
      url: process.env.ETHO_API + `/members?sort[name]=1&populate=primary_location&populate=locations&populate=organisations&populate=primary_organisation&filter[organisations]=${this.state.selectedOrganisation.value}`,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        this.setState({ userData: res.data })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  componentDidMount() {
    // get organsations
    axios({
      method: 'get',
      url: process.env.ETHO_API + `/organisations`,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        const organisations = res.data;
        const newArr = [{"label": "View all users", "value": ""}];
        organisations.forEach(el => {
          const obj = { "label": el.name, "value": el._id }
          newArr.push(obj);
        });
        this.setState({ selectedOrganisation: newArr[0], organisations: newArr }, () => {
          this.getUsers();
        });
      })    

  }

  handleOrganisationChange(event) {
    this.setState({ selectedOrganisation: event }, () => {
      this.getUsers();
    });
  }

  handleOpenModal() {
    this.setState({ showModal: true, modalType: 'add' });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleUpdateClick(userData) {
    this.setState({ showModal: true, updateData: userData, modalType: 'update' });
  }



  render() {

    return (
      <>
        <AdminNav />
        <Layout>
          <AppLayout>
            <Title border={true}>Users</Title>
            <AppLayoutContainer>

              <div className="mb-2">
                <label className="text-xs block mb-1">Change Organisation:</label>
                <Select options={this.state.organisations} value={this.state.selectedOrganisation} className="w-full md:w-64" onChange={(event) => this.handleOrganisationChange(event)} />
              </div>

              <Modal
                isOpen={this.state.showModal}
                contentLabel="Modal"
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={true}
                className="modal modal-sm"
                overlayClassName="modal-overlay"
              >
                <span onClick={this.handleCloseModal} className="modal-close-icon"><svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-10 h-10"><path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span>
                <UserModalContent data={this.state.updateData} deleteCallback={(id) => {
                  const updatedUserData =  _.filter(this.state.userData, (o) => {
                    return o._id !== id;
                  });
                  console.log(updatedUserData, this.state.updateData._id);
                  this.setState({ showModal: false, userData: updatedUserData});
                }} modalType={this.state.modalType} />
              </Modal>

              <Can
                role={getProfile().roles}
                perform="members:create"
                yes={() => 
                  <div className="flex justify-end mb-4">
                    <button onClick={this.handleOpenModal} className="inline-flex items-center cursor-pointer border-2 rounded-md border-blue-500 text-blue-500 hover:bg-blue-100 font-semibold pl-4 pr-5 py-2">
                      <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6 mr-2"><path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        Add User
                    </button>
                  </div>
                }
                no={() => null}
              />

              <div className="flex flex-wrap -mx-3">
                {this.state.userData.map((user, i) => {
                  return (
                    <div key={i} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3">
                      <UserCard userData={user} onUpdateClick={(userData) => this.handleUpdateClick(userData)} />
                    </div>
                  )
                })}
              </div>

            </AppLayoutContainer>
          </AppLayout>
        </Layout>
      </>
    );
  }
}

export default Users;
