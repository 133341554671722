import React from "react";
import PropTypes from "prop-types";
import CardAdmin from "../components/cardAdmin";
import Section from "../components/cardAdminSection";
import UpdateBtn from "../components/adminButtonUpdate";

/* Small Component for displaying key values */
class KeyValueSpan extends React.Component {
  render() {
    const label = this.props.label;
    const value = this.props.value;
    return (
      <div className="text-sm mb-2">
        <span className="block font-bold">{label}</span>
        <span className="block">{value}</span>
      </div>
    )
  }
}
KeyValueSpan.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node
};


class UserCard extends React.Component {

  render() {
    const userData = this.props.userData;

    return (
      <CardAdmin>
        <Section border={false}>
          <UpdateBtn onClick={() => this.props.onUpdateClick(userData)} />

        </Section>

        <Section>
          <div className="flex flex-wrap flex-row items-center mb-4">
            <span className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-white text-xs">{userData.name.split(" ").map((n) => n[0]).join("").toUpperCase()}</span>
            <span className="flex flex-1 ml-2 text-sm">{userData.name}</span>
          </div>

          {userData.vocation && (
            <KeyValueSpan label="Vocation:" value={userData.vocation} />
          )}

          {userData.work_email && (
            <KeyValueSpan label="Email:" value={userData.work_email} />
          )}

          {userData.primary_organisation && (
            <KeyValueSpan label="Primary Organisation:" value={userData.primary_organisation.name} />
          )}

          {userData.organisations && userData.organisations.length > 0 && (
            <div className="text-sm mb-2">
            <span className="block font-bold">Linked Organisations:</span>
              {userData.organisations.map((org, i) => (
                <span className="block" key={i}>{org.name}</span>
              ))}
            </div>
          )}

          {userData.primary_location && (
            <KeyValueSpan label="Primary Location:" value={userData.primary_location.name} />
          )}

          {userData.locations && userData.locations.length > 0 && (
          <div className="text-sm mb-2">
            <span className="block font-bold">Linked Locations:</span>
              {userData.locations.map((loc, i) => (
               <span className="block" key={i}>{loc.name}</span>
              ))}
            </div>
          )}

        </Section>

        <Section className="text-sm">
          <span className="block font-bold">Home working options:</span>
          <span className="block">
            {userData.work_from_home ? userData.work_from_home : "None"}</span>
        </Section>

      </CardAdmin>
    );
  }
}

UserCard.propTypes = {
  className: PropTypes.string,
  onUpdateClick: PropTypes.func,
  userData: PropTypes.object,
  openingTimes: PropTypes.array
};

export default UserCard;