import React from "react";
import {isAuthenticated, isBrowser  } from "../../utils/auth";
import PropTypes from 'prop-types';
import Can from "../../utils/can";
import NotFoundPage from "../../pages/404";
// import { Redirect } from "@reach/router";
import { navigate } from "gatsby";

const PrivateRoute = ({ component: Component, path, roles, perform, ...rest }) => {
  if (!isAuthenticated() && path !== `/app/login`) {
    let redirectUrl = isBrowser ? encodeURI(window.location.pathname + window.location.search) : "/";
    console.log("Exec login page...");
    // redirect to login page
    navigate(`/app/login?redirect=${redirectUrl}`);
    // return (<Redirect to={`/app/login?redirect=${redirectUrl}`} />)
    // loginPage(redirectUrl);
    return (
      <section className="text-center">
        <div className="leading-loose pt-8">
          Loading...
        </div>
      </section>
    );
  } else if(roles && perform) {
    console.log("Exec private route...", roles, perform);
    return <Can
      role={roles}
      perform={perform}
      yes={() => (
        <Component {...rest} />
      )}
      no={() => (
        <NotFoundPage />
      )}
    />
  } else {
    return <Component {...rest} />
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  roles: PropTypes.array,
  perform: PropTypes.string
}

export default PrivateRoute
