import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import AppLayout from "../../views/containers/appLayout";
import AppLayoutContainer from "../../views/containers/AppLayoutContainer";
import Title from "../../views/components/title";
import Layout from "../../views/components/layout";
const axios = require('axios').default;
import { getTokens } from "../../utils/auth";

import GreatIcon from "../../images/icon-great.svg";
import GoodIcon from "../../images/icon-good.svg";
import CopingIcon from "../../images/icon-coping.svg";
import StrugglingIcon from "../../images/icon-struggling.svg";
import AwfulIcon from "../../images/icon-awful.svg";
const icons = [
  {
    "label": "great",
    "icon": GreatIcon
  },
  {
    "label": "good",
    "icon": GoodIcon
  },
  {
    "label": "coping",
    "icon": CopingIcon
  },
  {
    "label": "struggling",
    "icon": StrugglingIcon
  },
  {
    "label": "awful",
    "icon": AwfulIcon
  },
];

const allMoodText = [
  {
    "mood": "great",
    "text": "Good to hear! If you would like to share some positive thoughts with someone who needs a little support, you can express interest in mentoring <a href='mailto:Mentoring@etho.co.uk'>here</a>"
  },
  {
    "mood": "good",
    "text": "Good to hear! If you would like to share some positive thoughts with someone who needs a little support, you can express interest in mentoring <a href='mailto:Mentoring@etho.co.uk'>here</a>"
  },
  {
    "mood": "coping",
    "text": "Life has its challenges, but we all have our ways of coping. If you need more support or would like to be connected to a mentor, please click <a href='mailto:Support@etho.co.uk'>here</a>"
  },
  {
    "mood": "struggling",
    "text": "We are sorry to hear that you are struggling; we have a collection of support resources that may be of some help <a href='https://www.etho.co.uk/wellbeing' target='_blank'>here</a>. If you would like to feedback any issues to your organisation, please use this <a href='mailto:Support@etho.co.uk'>link</a>"
  },
  {
    "mood": "awful",
    "text": "We are sorry to hear that things have got this bad; we have a collection of support resources that may be of some help <a href='https://www.etho.co.uk/wellbeing'  target='_blank'>here</a>. If you would like to feedback any issues to your organisation, please use <a href='mailto:Support@etho.co.uk'>this link<a>"
  }
];


class Thankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = { emotionally: '', countdown: 15 };
  }

  componentDidMount() {
    // get todays date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = mm + '-' + dd + '-' + yyyy;

    // get status today
    axios({
      method: 'get',
      url: `${process.env.ETHO_API}/me/status?[$gte]=${today}&sort[createdAt]=-1&page[limit]=1`,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        if(res.data[0].mood) {
          this.setState({ emotionally: res.data[0].mood })
        }
      })
      .catch((err) => {
        throw err;
      })

    // redirect after 15 seconds
    if (this.state.countdown != 0) {
      setInterval(() => {
        const remaining = this.state.countdown - 1;        
        this.setState({ countdown: remaining })
        if (remaining === 0) {
          window.location.href = process.env.DOMAIN;
        }
      }, 1000)
    }
  }

  render() {
    let moodData = this.state.emotionally;

    const mood = icons.filter((icon) => {
      if (icon.label == moodData) {
        return icon;
      }
    })[0];

    const moodText = allMoodText.filter((text) => {
      if (text.mood == moodData) {
        return text;
      }
    })[0];

    return (
      <Layout>
        <AppLayout>
          <Title border={true} className="h2">You are now checked out</Title>
          <AppLayoutContainer>
            <Title className="h2 mb-4">Thanks for telling us how you feel</Title>

            <div className="w-full lg:w-3/5 mx-auto text-center mb-1">
              {this.state.emotionally != '' && (
                <>
                  <img
                    alt={mood.label}
                    className="mx-auto mb-2"
                    src={mood.icon}
                  />
                  <span className="block font-bold mb-4 capitalize">{mood.label}</span>
                  <p className="mb-6" dangerouslySetInnerHTML={{ __html: moodText.text }} />
                </>
              )}
              <div className="text-sm text-gray-500">
                <p>Etho is dedicated to helping organisations to understand and support their people.</p>
                <p>This data is anonymised and can help highlight where things are going well and where they may need attention.</p>
              </div>
            </div>
          </AppLayoutContainer>

          <hr className="my-4" />
          <AppLayoutContainer>
            <p className="text-center">Heading back to the check in screen in {this.state.countdown} — <Link to="/" className="font-bold">Go there now</Link></p>
          </AppLayoutContainer>

        </AppLayout>
      </Layout >
    );
  }

}

Thankyou.propTypes = {
  location: PropTypes.object
};
export default Thankyou;
