import React from "react";
import PropTypes from 'prop-types';
import { getTokens } from "../../utils/auth";
import { asyncGetOptions } from '../../utils/helpers';
import Input from "../../views/components/input";
import Button from "../../views/components/button";
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
const axios = require('axios').default;

const initialState = { name: '', address: { line1: '', line2: '', county: '', city: '', postcode: '' }, admins: [], networks: [], locations: [], members: [] };

class LocationModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if update data is being passed through
    if (this.props.modalType === "update" && Object.entries(this.props.data).length > 0) {
      this.setState({ ...this.props.data }, () => {console.log(this.state); this.updateSelectState()}); // update state values that need to include data {label & value} for asyncSelect
    }
  }

 updateSelectState() {
    const selects = ["admins", "networks", "locations", "members"]
    selects.forEach(select => {
      var original = select;
      let currentValues = [];
      var selectedIDs = this.state[select];     
      if(select === "admins") {
        selectedIDs = selectedIDs.map((cur) => ( cur._id ) ); // just get _ids from populated admin array
        select = "members" // admins need to hit the 'members' endpoint so we make a special case for these
      }

      asyncGetOptions([], select)
      .then((els) => {
        els.forEach(el => {
          selectedIDs.forEach(ID => {
            if(el.value === ID) {
              currentValues.push({ label: el.label, value: ID })
            }
          })
        })
        if(original === "admins") { select = "admins" }
        this.setState({ [select]: currentValues }, () => console.log(this.state));
      })
  })
}

  asyncNetworks(inputValue, callback) {
    asyncGetOptions(inputValue, 'networks')
      .then((res) => {
        return callback(res);
      });
  }

  asyncAdmins(inputValue, callback) {
    asyncGetOptions(inputValue, 'members')
      .then((res) => {
        return callback(res);
      });
  }

  asyncLocations(inputValue, callback) {
    asyncGetOptions(inputValue, 'locations')
      .then((res) => {
        return callback(res);
      });
  }

  asyncMembers(inputValue, callback) {
    asyncGetOptions(inputValue, 'members')
      .then((res) => {
        return callback(res);
      });
  }

  handleAddressChange(key, value) {
    this.setState({ address: { ...this.state.address, [key]: value } }, () => console.log(this.state));
  }

  handleChange(key, value) {
    this.setState({ [key]: value }, () => console.log(this.state));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, address } = this.state;

    const admins = this.state.admins ? this.state.admins.map((el) => { return el.value }) : [];
    const networks = this.state.networks ?  this.state.networks.map((el) => { return el.value }) : [];
    const locations = this.state.locations ? this.state.locations.map((el) => { return el.value }) : [];
    const members = this.state.members ? this.state.members.map((el) => { return el.value }) : [];

    const formData = {
      name, address, admins, networks, locations, members
    };
    console.log(formData);

    if (this.props.modalType === "add") {
      axios({
        method: 'post',
        url: process.env.ETHO_API + '/organisations',
        data: { ...formData },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
        .then((organisation) => {
          toast.success("Successfully added organisation")
          setTimeout(() => { window.location.reload(); }, 1500);          
          console.log(organisation);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
    }

    else if (this.props.modalType === "update") {
      axios({
        method: 'patch',
        url: process.env.ETHO_API + '/organisations/' + this.state._id,
        data: { ...formData },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
        .then((organisation) => {
          toast.success("Successfully updated organisation");
          setTimeout(() => { window.location.reload(); }, 1500); 
          console.log(organisation);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} >
        <div className="px-5 py-4 text-center text-xl">Add Organisation</div>
        <hr className="mb-5" />
        <div className="px-8">
          <Input type="text" placeholder="e.g. Practice name" labelText="Name" onChange={(event) => this.handleChange("name", event.target.value)} value={this.state.name} />
          <Input type="text" placeholder="Line 1" labelText="Address" onChange={(event) => this.handleAddressChange("line1", event.target.value)} value={this.state.address.line1} />
          <Input type="text" placeholder="Line 2" onChange={(event) => this.handleAddressChange("line2", event.target.value)} value={this.state.address.line2} />
          <Input type="text" placeholder="e.g London" labelText="City" onChange={(event) => this.handleAddressChange("city", event.target.value)} value={this.state.address.city} />
          <Input type="text" placeholder="e.g Hertfordshire" labelText="County" onChange={(event) => this.handleAddressChange("county", event.target.value)} value={this.state.address.county} />
          <Input type="text" placeholder="e.g NW1 3NS" labelText="Postcode" onChange={(event) => this.handleAddressChange("postcode", event.target.value)} value={this.state.address.postcode} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8">
          <label className="block mt-4">Admins</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncAdmins} onChange={(event) => this.handleChange("admins", event)} value={this.state.admins} isMulti={true} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8 mb-5">
          <label className="block mt-4">Networks</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncNetworks} onChange={(event) => this.handleChange("networks", event)} value={this.state.networks} isMulti={true} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8 mb-5">
          <label className="block mt-4">Locations</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncLocations} onChange={(event) => this.handleChange("locations", event)} value={this.state.locations} isMulti={true} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8 mb-5">
          <label className="block mt-4">Users</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncMembers} onChange={(event) => this.handleChange("members", event)} value={this.state.members} isMulti={true} />
        </div>

        <div className="px-8 pb-6">
          <Button styleType="main">Confirm</Button>
        </div>

      </form>
    )
  }
}

LocationModalContent.propTypes = {
  modalType: PropTypes.string,
  data: PropTypes.object
}

export default LocationModalContent;