import React from "react";
import PropTypes from "prop-types";
import CardAdmin from "../components/cardAdmin";
import Section from "../components/cardAdminSection";
import Select from "react-select";
import UpdateBtn from "../components/adminButtonUpdate";
import { toast } from 'react-toastify';
import { getTokens } from "../../utils/auth";
const axios = require('axios').default;

class LocationCard extends React.Component {
  state = {
    selected: [],
    edited: false
  }

  componentDidMount() {
    this.getValues();
  }

  componentDidUpdate() {
    // We set 'edited' to true to stop componentDidUpdate resetting state with our original organisation data
    if (this.state.edited === true) return;
    this.getValues();
  }

  getValues = () => {
    const selectedIDs = this.props.data.organisations;
    const organisations = this.props.orgRefData;

    // get label & value of organisation
    let currentValues = [];
    organisations.forEach(organisation => {
      selectedIDs.forEach(ID => {
        if (organisation._id === ID) {
          currentValues.push({ label: organisation.name, value: ID });
        }
      })
    });

    // Workaround. If there is a difference between the 2, then we update state
    if (this.state.selected.toString() != currentValues.toString()) {
      this.setState({ selected: currentValues }), console.log(this.state);
    }
  }

  handleOrganisationChange(event, locationID) {
    let selectedOrgs = [];

    // if user empties the input
    if (event === null) {
      this.setState({ selected: [], edited: true });
    }

    else {
      this.setState({ selected: event, edited: true }, console.log(this.state.selected));
      // push input event data to empty array
      event.forEach(element => {
        selectedOrgs.push(element.value);
      });
    }

    // PATCH location with organisation
    axios({
      method: 'patch',
      url: process.env.ETHO_API + '/locations/' + locationID,
      data: { organisations: selectedOrgs },
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        toast.success("Successfully updated location");
        console.log(res.data);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err)
      })
  }


  render() {
    const organisationOptions = (props) => {
      return props.map((el) => { return { value: el._id, label: el.name } });
    };
    const locationData = this.props.data;
    const address = locationData.address;
    if (address) {
      ['loc', '_id', 'updatedAt', 'createdAt'].forEach(prop => {
        delete address[prop]
      });
    }

    return (
      <CardAdmin>

        <Section border={false}>
          <UpdateBtn onClick={() => this.props.onUpdateClick(locationData)} />
        </Section>

        <Section>
          <span className="block font-bold mb-1">{locationData.name}</span>
          <div className="text-sm">
            {locationData.address && (
              <div className="mb-4">
                {Object.keys(locationData.address).map((key, index) => (
                  <span key={index} className="block whitespace-pre-line">{locationData.address[key]}</span>
                ))
                }
              </div>
            )}

            {locationData.admins && (
              <>
                <span className="block text-xs font-bold">Admins</span>
                {locationData.admins.map((admin, i) => (
                  <span key={i} className="block">{admin.name}</span>
                ))}
              </>
            )}

          </div>
        </Section>

        <Section title="Organisations">
          <Select options={organisationOptions(this.props.orgRefData)} value={this.state.selected} onChange={(event) => this.handleOrganisationChange(event, locationData._id)} isMulti={true} isClearable={false} />
        </Section>

      </CardAdmin >
    );
  }
}

LocationCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  orgRefData: PropTypes.array,
  onUpdateClick: PropTypes.func
};

export default LocationCard;
