import React from "react";
import PropTypes from "prop-types";

class Button extends React.Component {
  render() {
    // Add logic for props, types etc here
    var styleType = this.props.styleType || "main";
    styleType = "btn-" + styleType;
    const additionalClasses = this.props.className || "";

    return (
      <button
        className={[
          "btn",
          styleType,
          "w-full",
          additionalClasses,
        ].join(" ")}
        value="submit"
        onClick={this.props.onClick}>
        {this.props.children}
      </button>
    );
  }
}

Button.propTypes = {
  styleType: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default Button;
