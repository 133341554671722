import React from "react";

import {getProfile, getTokens} from "../../utils/auth";

import AppLayout from "../../views/containers/appLayout";
import AppLayoutContainer from "../../views/containers/AppLayoutContainer";
import Layout from "../../views/components/layout";
import SEO from "../../views/components/seo";
import Title from "../../views/components/title";
import Can from "../../utils/can";


function ProfilePage() {

    let user = getProfile();
    let tokens = getTokens();
    return (
        <Layout>
          <SEO
            keywords={[]}
            title="Profile"
          />
          <AppLayout>
            <Title border={true}>Profile</Title>
            <AppLayoutContainer>
            <Can
              role={user.roles}
              perform="profile-page:visit"
              yes={() => (
                <h2>User can do it</h2>
              )}
              no={() => <h2>User can not do it</h2>}
            />
              <ul>
                <li><b>Access Token</b></li>
                <li>{tokens.accessToken}</li>
              </ul>
              <hr/>
              <ul>
                {Object.keys(user).map((key) =>
                    (
                        <>
                            <li><b>{key}</b></li>
                            <li>{
                              Array.isArray(user[key]) ? user[key].join(", ") : user[key]
                            }</li>
                        </>
                    )
                )}
              </ul>
            </AppLayoutContainer>
          </AppLayout>
        </Layout>
    )
}

export default ProfilePage;
