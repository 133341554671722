import React from "react";
import { navigate } from "@reach/router";
import AppLayout from "../../views/containers/appLayout";
import AppLayoutContainer from "../../views/containers/AppLayoutContainer";
import Title from "../../views/components/title";
import Layout from "../../views/components/layout";
import Button from "../../views/components/button";
import { toast } from 'react-toastify';
const axios = require('axios').default;
import { getTokens } from "../../utils/auth";

import WellIcon from "../../images/icon-well.svg";
import BorderlineIcon from "../../images/icon-borderline.svg";
import UnwellIcon from "../../images/icon-unwell.svg";

const topIcons = [
  {
    "label": "Well",
    "icon": WellIcon
  },
  {
    "label": "Borderline",
    "icon": BorderlineIcon
  },
  {
    "label": "Unwell",
    "icon": UnwellIcon
  },
];

import GreatIcon from "../../images/icon-great.svg";
import GoodIcon from "../../images/icon-good.svg";
import CopingIcon from "../../images/icon-coping.svg";
import StrugglingIcon from "../../images/icon-struggling.svg";
import AwfulIcon from "../../images/icon-awful.svg";
const icons = [
  {
    "label": "Great",
    "icon": GreatIcon
  },
  {
    "label": "Good",
    "icon": GoodIcon
  },
  {
    "label": "Coping",
    "icon": CopingIcon
  },
  {
    "label": "Struggling",
    "icon": StrugglingIcon
  },
  {
    "label": "Awful",
    "icon": AwfulIcon
  },
];


class Wellbeing extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickPhysically = this.handleClickPhysically.bind(this);
    this.handleClickEmotionally = this.handleClickEmotionally.bind(this);
    this.state = { physically: '', emotionally: '', attendance: {} };
  }

  componentDidMount() {
    // get todays date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = mm + '-' + dd + '-' + yyyy;

    // get attendances today
    axios({
      method: 'get',
      url: `${process.env.ETHO_API}/me/attendances?filter[check_in_time][$gte]=${today}&sort[check_in_time]=-1&page[limit]=1`,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        if (!res.data || res.data.length == 0 || res.data[0].check_out_time) {
          // user not logged in, so navigate to check-in
          navigate("/app/attendance")
        } else {
          this.setState({ attendance: res.data[0] });
        }
      })
      .catch((err) => {
        throw err;
      })
  }

  handleClickPhysically(wellness) {
    this.setState({ physically: wellness })
  }

  handleClickEmotionally(wellness) {
    this.setState({ emotionally: wellness })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    // if no wellbeing data, update attendance & exit
    if (this.state.emotionally === '' && this.state.physically === '') {
      axios({
        method: 'patch',
        url: process.env.ETHO_API + '/me/attendances/' + this.state.attendance._id,
        data: { check_out_time: Date.now() },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      }).then(() => {
        navigate(process.env.DOMAIN + "/");
      });
      return;
    }

    // if we have wellbeing data...
    // create status
    let statusReq = {};
    if(this.state.physically && this.state.physically !== "") { statusReq.health = this.state.physically.toLowerCase(); }
    if(this.state.emotionally && this.state.emotionally !== "") { statusReq.mood = this.state.emotionally.toLowerCase(); }
    axios({
      method: 'post',
      url: process.env.ETHO_API + '/me/status',
      // data: { health: this.state.physically.toLowerCase(), mood: this.state.emotionally.toLowerCase() },
      data: statusReq,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    }).then((res) => {
      // update attendance
      let statusId = res.data._id;
      axios({
        method: 'patch',
        url: process.env.ETHO_API + '/me/attendances/' + this.state.attendance._id,
        data: { status: statusId, check_out_time: Date.now() },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      }).then(() => {
        navigate(process.env.DOMAIN + "/app/attendance/thankyou")
      });
    })
      .catch((err) => {
        toast.error(err);
      })
  }

  render() {
    return (
      <Layout>
        <AppLayout>
          <Title className="h2">How are you feeling today?</Title>
          <p className="text-center">Physically I am...</p>
          <AppLayoutContainer>
            <div className="w-full sm:w-96 mx-auto">
              <div className="w-full sm:flex text-center items-center justify-center -mx-1">
                {topIcons.map((icon, index) => {
                  return (
                    <div key={index} className="flex-1 px-1">
                      <div className={"p-5 rounded cursor-pointer" + (this.state.physically === icon.label ? " bg-gray-200 hover:bg-gray-200 " : " hover:bg-gray-100")} onClick={() => this.handleClickPhysically(icon.label)}>
                        <img
                          alt={icon.label}
                          className="mx-auto mb-3"
                          src={icon.icon}
                        />
                        <span className="block font-bold">{icon.label}</span>
                      </div>
                    </div>
                  )
                }
                )}

              </div>
            </div>
          </AppLayoutContainer>

          <hr className="" />

          <AppLayoutContainer>
            <p className="text-center mt-3 mb-4">Emotionally I am...</p>
            <div className="w-full lg:w-4/5 xl:w-2/3 mx-auto text-center mb-1">
              <div className="flex flex-row flex-wrap -mx-1 justify-center">
                {icons.map((icon, index) => {
                  return (
                    <div className="w-1/2 sm:w-1/3 md:w-1/5 lg:flex-1 mb-4 px-1" key={index}>
                      <div onClick={() => this.handleClickEmotionally(icon.label)} className={"px-5 rounded cursor-pointer" + (this.state.emotionally === icon.label ? " bg-gray-200 hover:bg-gray-200 " : " hover:bg-gray-100")}>
                        <img
                          alt={icon.label}
                          className="mx-auto mb-1 cursor-pointer"
                          src={icon.icon}
                        />
                        <span className="font-bold">{icon.label}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </AppLayoutContainer>

          <hr className="mb-5" />

          <AppLayoutContainer>
            <div className="w-full sm:w-80 mb-6 mx-auto">
              <Button onClick={this.handleSubmit}>Continue</Button>
            </div>
          </AppLayoutContainer>

        </AppLayout>
      </Layout >
    );
  }

}

export default Wellbeing;
