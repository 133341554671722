import React from "react";
import PropTypes from "prop-types";

class CardAdmin extends React.Component {

    render() {
        const additionalClasses = this.props.className || "";
        return (
            <div className={[
                "bg-white shadow-md rounded-lg mb-6",
                additionalClasses,
            ].join(" ")}>
                {this.props.children}
            </div>
        )
    }
}

CardAdmin.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
  };

export default CardAdmin;