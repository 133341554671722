import React from "react";
import { Link } from "gatsby"

function AdminNav() {
    return (
        <div className="adminMenu hidden md:block absolute shadow left-0 bg-white w-32 rounded-b z-20">           
            <ul className="text-center text-sm">
                <li><Link to="/app/admin">Dashboard</Link></li>
                <li><Link to="/app/admin/networks/">Networks</Link></li>
                <li><Link to="/app/admin/organisations/">Organisations</Link></li>
                <li><Link to="/app/admin/locations">Locations</Link></li>
                <li><Link to="/app/admin/users/">Users</Link></li>
            </ul>
        </div>
    )
}

export default AdminNav;