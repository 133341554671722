import React from "react";
import PropTypes from "prop-types";

class Input extends React.Component {
  render() {
    // Add logic for props, types etc here
    return (
      <div className="inputGroup">
        {this.props.labelText && (
          <label className="block">
            {this.props.labelText}
          </label>
        )
        }
        <input type={this.props.type} {...{placeholder: this.props.placeholder}} value={this.props.value || ""} {...{onChange: this.props.onChange}} />
      </div>
    );
  }
}

Input.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.node.isRequired,
  type: PropTypes.node.isRequired,
  onChange: PropTypes.func
};

export default Input;
