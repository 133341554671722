import React from "react";
import DashboardLayout from "../../views/containers/dashboardLayout";
var Promise = require('promise');
import Select from "react-select";
import Title from "../../views/components/title";
import Layout from "../../views/components/layout";
import AdminNav from "../../views/components/adminNav";
import ReactTable from 'react-table-v6';
import ActiveTeamChart from '../../views/components/activeTeamChart'
import WellbeingChart from '../../views/components/wellbeingChart'
const axios = require('axios').default;
import { getTokens } from "../../utils/auth";
import _ from 'lodash';


// Health Icons
import WellIcon from "../../images/icon-sm-well.svg";
import BorderlineIcon from "../../images/icon-sm-borderline.svg";
import UnwellIcon from "../../images/icon-sm-unwell.svg";
import IconNotCheckedIn from "../../images/icon-not-checked-in.svg";

const healthIcons = [
  {
    "label": "well",
    "icon": WellIcon
  },
  {
    "label": "borderline",
    "icon": BorderlineIcon
  },
  {
    "label": "unwell",
    "icon": UnwellIcon
  },
];

// Mood Icons
import GreatIcon from "../../images/icon-great.svg";
import GoodIcon from "../../images/icon-good.svg";
import CopingIcon from "../../images/icon-coping.svg";
import StrugglingIcon from "../../images/icon-struggling.svg";
import AwfulIcon from "../../images/icon-awful.svg";
const moodIcons = [
  {
    "label": "great",
    "icon": GreatIcon
  },
  {
    "label": "good",
    "icon": GoodIcon
  },
  {
    "label": "coping",
    "icon": CopingIcon
  },
  {
    "label": "struggling",
    "icon": StrugglingIcon
  },
  {
    "label": "awful",
    "icon": AwfulIcon
  },
];

// Location Icons
import AtWork from "../../images/icon-on-location.svg";
import CheckedOut from "../../images/icon-checked-out.svg";
import HomeWorking from "../../images/icon-home-working.svg";
import DayOff from "../../images/icon-day-off.svg";
import OffSick from "../../images/icon-off-sick.svg";
import Available from "../../images/icon-available.svg";
import SelfIsolating from "../../images/icon-self-isolating.svg";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedOrganisation: "",
      organisations: [],
      userData: [],
      yestData: [],
      locationCount: 0,
      homeCount: 0,
      sickCount: 0,
      uncheckedCount: 0,
      wellCount: 0,
      borderlineCount: 0,
      unwellCount: 0,
      selfIsolatingCount: 0,
      availableCount: 0,
      dayOffCount: 0
    };
  }


  async getLastAttendance(memberId) {
    const res = await axios({
      method: 'get',
      url: process.env.ETHO_API + `/attendances?populate=member&populate=status&populate=location&populate=organisation&filter[member][_id]=${memberId}&sort[check_in_time]=-1&limit=1`,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    });
    return res.data;
 }

  formatAttendanceData = async (attendances, allMembers, healthData, moodData, requestedDate, getLatestAttendance) => {
    let attArray = [];

    attendances.forEach(attendance => {
      // for each attendance, remove this cur member from allMembers
      if(!attendance.member) {
        return;
      }
      const attMemberID = attendance.member?._id;
      allMembers.forEach((member, index) => {
        if(member._id === attMemberID) {
          allMembers.splice(index, 1);
        }
      });

      let userObj = {};
      userObj._id = attendance.member?._id;
      userObj.name = attendance.member?.name;
      userObj.vocation = attendance.member?.vocation ? attendance.member?.vocation : "";

      userObj.type = attendance.type;
      // Location
      var location;
      if (attendance.type === "location" && (attendance.check_out_time == null || (new Date(attendance.check_out_time)) > Date.now())) { // working
        location = { name: attendance.location.name, icon: AtWork };
      }
      else if (attendance.type === "location" && (attendance.check_out_time != null && (new Date(attendance.check_out_time)) <= Date.now())) {  // home working
        location = { name: "Checked out", icon: CheckedOut };
      }
      else if (attendance.type === "organisation" && attendance.check_out_time == null) {  // home working
        location = { name: attendance.organisation.name, icon: HomeWorking };
      }
      else if (attendance.type === "organisation" && attendance.check_out_time != null) {  // home working
        location = { name: "Checked out", icon: CheckedOut };
      }
      else { // person is not working
        const type = attendance.type;
        if (type === "day-off") {
          location = { name: "Day Off", icon: DayOff };
        }
        else if (type === "sick") {
          location = { name: "Off Sick", icon: OffSick };
        }
        else if (type === "sick-covid-confirmed") {
          location = { name: "Off Sick - Covid Confirmed", icon: OffSick };
        }
        else if (type === "sick-covid-symptoms") {
          location = { name: "Off Sick - Covid Symptoms", icon: OffSick };
        }
        else if (type === "unable-to-work") {
          location = { name: "Unable to work - Caring responsibilities", icon: DayOff };
        }
      }
      userObj.location = location;

      // Health
      const health = attendance.status ? attendance.status.health : null;
      if (!health) { // if no health has been set, return empty string
        userObj.health = "";
      }
      else {
        const getHealth = healthIcons.filter((cur) => (
          cur.label === health
          ));
          userObj.health = getHealth[0];
        }
        if(attendance.member?._id == "5eff475f90653600334f7557") {
          console.log("Tracking HEALTH....");
          console.log(attendance.status?.health);
          console.log(userObj.health);
        }

      // Mood
      const mood = attendance.status ? attendance.status.mood : null;
      if (!mood) {
        userObj.mood = "";
      }
      else {
        const getMood = moodIcons.filter((cur) => (
          cur.label === mood
        ));
        userObj.mood = getMood[0];
      }

      // Self Isolating
      var selfIsolating = (attendance.self_isolating && attendance.self_isolating == true) ? true : false;
      userObj.self_isolating = selfIsolating;

      // Available
      var available = (attendance.available && attendance.available == true) ? true : false;
      userObj.available = available;

      // Push to array
      if(attArray.filter(o => o._id === userObj._id).length === 0)
      {
        attArray.push(userObj);
      }
    });

    // Loop through remaining allMembers and add to attArray as not checked in
    // loop through remaining members and check last attendance to see if off sick
    await Promise.all(allMembers.map(async (member) => {
      let userObj = {};
      // get latest attendance
      userObj.self_isolating = false; // default to false
      if(getLatestAttendance) {
        //console.log(`getting latest checkin for ${member.name}`);
        let latestCheckinUrl = process.env.ETHO_API + `/attendances?filter[member]=${member._id}&filter[check_in_time][$lte]=${requestedDate}&sort[check_in_time]=-1&page[limit]=1`; // latest attendance for user
        let latestCheckin = await axios({
          method: "get",
          url: latestCheckinUrl,
          headers: { Authorization: "Bearer " + getTokens().accessToken },
        }).then(function (response) {
          return {
            success: true,
            data: response.data,
          };
        }).catch(function (error) {
          return { success: false, message: error };
        });
        if(latestCheckin.success && latestCheckin.data.length == 1) {
          userObj.self_isolating = latestCheckin.data[0].self_isolating;
        }
      }

      userObj._id = member._id;
      userObj.name = member.name;
      userObj.vocation = member.vocation ? member.vocation : "";
      userObj.location = { name: "Not Checked In", icon: IconNotCheckedIn };
      userObj.available = false;
      userObj.health = "";
      userObj.mood = "";
      userObj.type = "not-checked-in";
      // console.log("NOT CHECKED IN: "+member.name);
      attArray.push(userObj);
    }));    

    attArray.forEach((member) => {
      let health = healthData.filter(o => o._id === member._id);
      if(health && health.length > 0) {
        console.log("updating health for "+member.name+" - "+health[0].lastHealth)
        const getHealth = healthIcons.filter((cur) => (
          cur.label === health[0].lastHealth
        ));
        member.health = getHealth[0];
      }

      let mood = moodData.filter(o => o._id === member._id);
      if(mood && mood.length > 0) {
        console.log("updating mood for "+member.name+" - "+mood[0].lastMood)
        const getMood = moodIcons.filter((cur) => (
          cur.label === mood[0].lastMood
        ));
        member.mood = getMood[0];
      }
    })
  
    return attArray;
  }


  getCheckInsByDate = async (today, getLatestAttendance = false) => {
    // get todays date
    return new Promise((resolve, reject) => {
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) { dd = '0' + dd; }
      if (mm < 10) { mm = '0' + mm; }
      var tFormat = mm + '-' + dd + '-' + yyyy;

      var ms = today.getTime() + 86400000;
      var tomorrow = new Date(ms);
      dd = tomorrow.getDate();
      mm = tomorrow.getMonth() + 1;
      yyyy = tomorrow.getFullYear();
      if (dd < 10) { dd = '0' + dd; }
      if (mm < 10) { mm = '0' + mm; }
      var tomorrowFormat = mm + '-' + dd + '-' + yyyy;

      // Get all members and attendances
      axios({
        method: 'get',
        url: process.env.ETHO_API + `/members?filter[organisations]=${this.state.selectedOrganisation.value}`,
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
      .then((allMembers) => {
        axios({
          method: 'get',
          url: process.env.ETHO_API + `/attendances?populate=member&populate=status&populate=location&populate=organisation&filter[organisation][_id]=${this.state.selectedOrganisation.value}&filter[check_in_time][$gte]=${tFormat}&sort[check_in_time]=-1`,
          headers: { "Authorization": "Bearer " + getTokens().accessToken }
        })
        .then((res) => {
          axios({
            method: 'get',
            url: process.env.ETHO_API + `/health`,
            headers: { "Authorization": "Bearer " + getTokens().accessToken }
          })
          .then(async (healthData) => {
            axios({
              method: 'get',
              url: process.env.ETHO_API + `/mood`,
              headers: { "Authorization": "Bearer " + getTokens().accessToken }
            })
            .then(async (moodData) => {
              const filteredResData = _.filter(res.data || [], o => new Date(o.check_in_time) < new Date(tomorrowFormat) );

              const attArray = await this.formatAttendanceData(filteredResData || [], allMembers.data, healthData.data, moodData.data, tFormat, getLatestAttendance);
              // location
              const locationCount = attArray.filter((cur) => (
                cur.type === "location"
              )).length;

              // home working
              const homeCount = attArray.filter((cur) => (
                cur.type === "organisation"
              )).length;

              // off sick
              const sickCount = attArray.filter((cur) => (
                cur.type === "sick" || cur.type === "sick-covid-confirmed" || cur.type === "sick-covid-symptoms" || cur.type === "unable-to-work"
              )).length;

              // unchecked
              const uncheckedCount = attArray.filter((cur) => (
                cur.health === ""
              )).length;

              // health status count
              const wellCount = attArray.filter((cur) => (
                cur.health.label && cur.health.label === "well"
              )).length;
              const borderlineCount = attArray.filter((cur) => (
                cur.health.label && cur.health.label === "borderline"
              )).length;
              const unwellCount = attArray.filter((cur) => (
                cur.health.label && cur.health.label === "unwell"
              )).length;

              // additional counts
              const selfIsolatingCount = attArray.filter((cur) => (
                cur.self_isolating == true
              )).length;
              const availableCount = attArray.filter((cur) => (
                cur.available == true
              )).length;
              const dayOffCount = attArray.filter((cur) => (
                cur.type === "day-off"
              )).length;
              resolve({ userData: attArray, loading: false, locationCount, homeCount, sickCount, uncheckedCount, wellCount, borderlineCount, unwellCount, dayOffCount, selfIsolatingCount, availableCount }); 
              })
          })
        })
        .catch((err) => {
          reject(err)
        })
      })
    })
  }

  async getAttendances() {
    const today = new Date()
    var yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    await this.getCheckInsByDate(today, true).then((res) => {
      this.setState(res)
    });

    this.getCheckInsByDate(yesterday).then((res) => {
      this.setState({ yestData: res.userData })
    })
  }

  async handleOrganisationChange(event) {
    this.setState({ loading: true, selectedOrganisation: event }, async () => {
      await this.getAttendances();
    });
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: process.env.ETHO_API + `/organisations`,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        const organisations = res.data || [];
        const newArr = [];
        organisations.forEach(el => {
          const obj = { "label": el.name, "value": el._id }
          newArr.push(obj);
        });
        this.setState({ selectedOrganisation: newArr.length > 0 ? newArr[0] : "", organisations: newArr }, async () => {
          await this.getAttendances();
        });
      })
  }

  render() {
    const columns = [{
      Header: 'Name',
      accessor: 'name' // key in userData
    }, {
      Header: 'Vocation',
      accessor: 'vocation'
    }, {
      Header: 'Location',
      id: 'location',
      accessor: d => d.location?.name,
      Cell: (row) => {
        return (
          <div>
            {row.original.location?.icon && (<img className="locationIcon" alt={row.value} src={row.original.location?.icon} />)} {row.original.type === "organisation" && row.value !== "Checked out" ? "Home Working" : row.value}</div>
        )
      },
    }, {
      Header: 'Health',
      id: 'health',
      accessor: d => d.health.label ? d.health.label : "",
      Cell: (row) => {
        return (
          <div>
            {row.original.health.icon ? (<img className="healthIcon" alt={row.original.health.label} src={row.original.health.icon} />) : <img className="healthIcon" alt="None" src={IconNotCheckedIn} />}
          </div>
        )
      },
    }, {
      Header: 'Wellbeing',
      id: 'wellbeing',
      accessor: d => d.mood.label ? d.mood.label : "",
      Cell: (row) => {
        return (
          <div>
            {row.original.mood.icon ? (<img className="healthIcon" alt={row.original.mood.label} src={row.original.mood.icon} />) : <img className="healthIcon" alt="None" src={IconNotCheckedIn} />}
          </div>
        )
      },
    },
    {
      Header: 'Further Info',
      id: 'further-info',
      accessor: d => d.mood.label ? d.mood.label : "",
      Cell: (row) => {
        return (
          <div>
            {row.original.self_isolating && (<img className="healthIcon ml-1" alt="Self Isolating" src={SelfIsolating} />)}
            {row.original.available && (<img className="healthIcon ml-1" alt="Self Isolating" src={Available} />)}
          </div>
        )
      }
    }
    ]
    return (
      <>
        <AdminNav />
        <Layout>
          {this.state.loading ? (
          <section className="block bg-gray-100 pb-10 md:pt-10 h-full md:pl-32">
            <div className="container px-8 block pt-12 mx-auto">
              <div className="bg-white shadow-sm border rounded-sm">
                <p className="p-8 text-center">Loading...</p>
              </div> 
            </div> 
          </section>
          ) : (
          <section className="block bg-gray-100 pb-10 md:pt-10 h-full md:pl-32">
            <div className="container px-8 block pt-12 mx-auto">
              <div className="bg-white shadow-sm border rounded-sm">
                <Title border={true}>{this.state.selectedOrganisation.label || ""}</Title>
                <div className="p-8">
                  {this.state.organisations.length > 1 && (
                    <div className="mb-2">
                      <label className="text-xs block mb-1">Change Organisation:</label>
                      <Select options={this.state.organisations} value={this.state.selectedOrganisation} placeholder={"Change Organisation"} className="w-full md:w-64" onChange={async (event) => await this.handleOrganisationChange(event)} />
                    </div>
                  )}
                  <div className="shadow-md rounded-md sm:p-5 hidden sm:block">
                    <span className="text-sm">Active Team</span>
                    <ActiveTeamChart todayData={this.state.userData} yestData={this.state.yestData} />
                    <span className="text-sm">Team wellbeing</span>
                    <WellbeingChart todayData={this.state.userData} yestData={this.state.yestData} />
                  </div>

                  {/* Mobile View */}
                  <div className="shadow-md rounded-md p-5 block sm:hidden">
                    <div className="text-xs mb-5">Active Team</div>
                    <div className="mb-1"><img src={AtWork} className="inline-block mr-2 h-6" /><span className="font-bold">{this.state.locationCount}</span> at work</div>
                    <div className="mb-1"><img src={HomeWorking} className="inline-block mr-2 h-6" /><span className="font-bold">{this.state.homeCount}</span> at home</div>
                    <div className="mb-1"><img src={OffSick} className="inline-block mr-2 h-6" /><span className="font-bold">{this.state.sickCount}</span> off sick</div>
                    <div className="mb-1"><img src={DayOff} className="inline-block mr-2 h-6" /><span className="font-bold">{this.state.dayOffCount}</span> day off</div>
                    <div className="mb-1"><img src={IconNotCheckedIn} className="inline-block mr-2 h-6" /><span className="font-bold">{this.state.uncheckedCount}</span> not checked</div>
                  </div>

                </div>

                <div className="flex flex-wrap border-t">
                  <div className="w-full md:w-1/2 pt-8">
                    <div className="flex flex-wrap">
                      <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
                        <div className="text-center">
                          <img src={WellIcon} alt="well" className="mx-auto mb-3 h-8" />
                          <span className="block font-bold text-xl">{this.state.wellCount}</span>
                          <span>Well</span>
                        </div>
                      </div>

                      <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
                        <div className="text-center">
                          <img src={BorderlineIcon} alt="well" className="mx-auto mb-3 h-8" />
                          <span className="block font-bold text-xl">{this.state.borderlineCount}</span>
                          <span>Borderline</span>
                        </div>
                      </div>

                      <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
                        <div className="text-center">
                          <img src={UnwellIcon} alt="well" className="mx-auto mb-3 h-8" />
                          <span className="block font-bold text-xl">{this.state.unwellCount}</span>
                          <span>Unwell</span>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="w-full md:w-1/2 border-l pt-8 pb-6">
                    <div className="flex flex-wrap">
                      <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
                        <div className="text-center">
                          <img src={SelfIsolating} alt="well" className="mx-auto mb-3 h-8" />
                          <span className="block font-bold text-xl">{this.state.selfIsolatingCount}</span>
                          <span>Self isolating</span>
                        </div>
                      </div>

                      <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
                        <div className="text-center">
                          <img src={Available} alt="well" className="mx-auto mb-3 h-8" />
                          <span className="block font-bold text-xl">{this.state.availableCount}</span>
                          <span className="block">Available</span>
                          <span className="block text-sm text-gray-500">(if needed)</span>
                        </div>
                      </div>

                      <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
                        <div className="text-center">
                          <img src={DayOff} alt="well" className="mx-auto mb-3 h-8" />
                          <span className="block font-bold text-xl">{this.state.dayOffCount}</span>
                          <span>Day Off</span>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div className="block">
              <DashboardLayout>
                {this.state.userData.length > 0 && (
                  <ReactTable
                    loading={this.state.loading}
                    data={this.state.userData}
                    columns={columns}
                    minRows={0}
                    defaultPageSize={200}
                    resizable={false}
                    defaultSorted={[
                      {
                        id: "name",
                        asc: true
                      }
                    ]}
                  />
                )
                }
              </DashboardLayout>
            </div>
          </section>
          )}
        </Layout>
      </>
    );
  }
}

export default Dashboard;
