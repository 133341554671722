import React from "react";
import PropTypes from "prop-types";

class Section extends React.Component {
    render() {
        const additionalClasses = this.props.className || "";
        const border = (this.props.border === false) ? false : true;
        return (
            <div className={[
                "p-4",
                additionalClasses,
                (border) ? "border-t" : ""
            ].join(" ")}>
                {this.props.title ? <span className="block font-bold mb-1">{this.props.title}</span> : ""}
                {this.props.children}
            </div>
        )
    }
}

Section.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    border: PropTypes.bool,
    children: PropTypes.node.isRequired
};

export default Section;