import React from "react";
import PropTypes from "prop-types";

class AdminButtonUpdate extends React.Component {
    render() {
        return (
            <span className="block cursor-pointer font-bold text-center text-blue-500" onClick={this.props.onClick}>Update</span>
        );
    }
}

AdminButtonUpdate.propTypes = {    
    onClick: PropTypes.func
  };

export default AdminButtonUpdate;


