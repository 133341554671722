import React from "react";
import PropTypes from "prop-types";

class Checkbox extends React.Component {
  render() {
    // Add logic for props, types etc here
    return (
      <label className="flex items-center cursor-pointer font-regular mt-2">
        <input type="checkbox" className="form-checkbox" {...this.props} />
        <span className="ml-2">{this.props.label}</span>
      </label>


    );
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.node,
  type: PropTypes.node,
  rest: PropTypes.object
};

export default Checkbox;
