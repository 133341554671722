import React from "react";
import PropTypes from 'prop-types';
import { getTokens } from "../../utils/auth";
import { asyncGetOptions } from '../../utils/helpers';
import Button from "../../views/components/button";
import Input from "../../views/components/input";
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
const axios = require('axios').default;

const initialState = { name: '', admins: [], networks: [], organisations: [] };

class NetworkModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if update data is being passed through
    if (this.props.modalType === "update" && Object.entries(this.props.data).length > 0) {
      this.setState({ ...this.props.data }, () => this.updateSelectState()); // update state values that need to include data {label & value} for asyncSelect
    }
  }

 updateSelectState() {
    const selects = ["admins", "networks", "organisations"]
    selects.forEach(select => {
      var original = select;
      let currentValues = [];
      var selectedIDs = this.state[select];     
      if(select === "admins") {
        selectedIDs = selectedIDs.map((cur) => ( cur._id ) ); // just get _ids from populated admin array
        select = "members" // admins need to hit the 'members' endpoint so we make a special case for these
      }

      asyncGetOptions([], select)
      .then((els) => {
        els.forEach(el => {
          selectedIDs.forEach(ID => {
            if(el.value === ID) {
              currentValues.push({ label: el.label, value: ID })
            }
          })
        })
        if(original === "admins") { select = "admins" }
        this.setState({ [select]: currentValues }, () => console.log(this.state));
      })
  })
}

  asyncNetworks(inputValue, callback) {
    asyncGetOptions(inputValue, 'networks')
      .then((res) => {
        return callback(res);
      });
  }

  asyncAdmins(inputValue, callback) {
    asyncGetOptions(inputValue, 'members')
      .then((res) => {
        return callback(res);
      });
  }

  asyncOrganisations(inputValue, callback) {
    asyncGetOptions(inputValue, 'organisations')
      .then((res) => {
        return callback(res);
      });
  }

  handleChange(key, value) {
    this.setState({ [key]: value }, () => console.log(this.state));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { name } = this.state;
    // only return values from selects
    const admins = this.state.admins ? this.state.admins.map((el) => { return el.value }) : [];
    const networks = this.state.networks ?  this.state.networks.map((el) => { return el.value }) : [];
    const organisations = this.state.organisations ? this.state.organisations.map((el) => { return el.value }) : [];

    const formData = {
      name, admins, networks, organisations
    };

    if (this.props.modalType === "add") {
      axios({
        method: 'post',
        url: process.env.ETHO_API + '/networks',
        data: { ...formData },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
        .then((organisation) => {
          toast.success("Successfully updated network")
          setTimeout(() => { window.location.reload(); }, 1500);          
          console.log(organisation);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
    }

    else if (this.props.modalType === "update") {
      axios({
        method: 'patch',
        url: process.env.ETHO_API + '/networks/' + this.state._id,
        data: { ...formData },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
        .then((organisation) => {
          toast.success("Successfully updated network");
          setTimeout(() => { window.location.reload(); }, 1500); 
          console.log(organisation);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} >
        <div className="px-5 py-4 text-center text-xl">Add Network</div>
        <hr className="mb-5" />
        <div className="px-8">
          <Input type="text" placeholder="e.g. Network Name" labelText="Network Name" onChange={(event) => this.handleChange("name", event.target.value)} value={this.state.name} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8">
          <label className="block mt-4">Admins</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncAdmins} onChange={(event) => this.handleChange("admins", event)} value={this.state.admins} isMulti={true} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8 mb-5">
          <label className="block mt-4">Child Networks</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncNetworks} onChange={(event) => this.handleChange("networks", event)} value={this.state.networks} isMulti={true} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8 mb-5">
          <label className="block mt-4">Connected Organisations</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncOrganisations} onChange={(event) => this.handleChange("organisations", event)} value={this.state.organisations} isMulti={true} />
        </div>

        <div className="px-8 pb-6">
          <Button styleType="main">Confirm</Button>
        </div>

      </form>
    )
  }
}

NetworkModalContent.propTypes = {
  modalType: PropTypes.string,
  data: PropTypes.object
}

export default NetworkModalContent;