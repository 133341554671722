// Libs
import React from "react";
import { navigate } from "gatsby";
import { Router, useLocation } from "@reach/router";
// Utils
import PrivateRoute from "../views/components/privateRoute";
import { isAuthenticated, logout, getProfile, loginPage } from "../utils/auth";
//import Can from "../utils/can";
// Pages
import NotFoundPage from "../pages/404";
// import LoginPage from "./app/login";
import ProfilePage from "../_pages/app/profile";
import CheckIn from "../_pages/attendance/checkIn";
import CheckOut from "../_pages/attendance/checkOut";
import Wellbeing from "../_pages/attendance/wellbeing";
import Thankyou from "../_pages/attendance/thankyou";
import AdminDashboard from "../_pages/admin/dashboard";
import AdminUsers from "../_pages/admin/users";
import AdminOrganisation from "../_pages/admin/organisations";
import AdminLocations from "../_pages/admin/locations";
import AdminNetworks from "../_pages/admin/networks";
import IndexPage from ".";
import { parse } from "query-string";

const App = () => {
  return (
    <Router basepath="/app">
      <PrivateRoute path="/profile" component={ProfilePage} />
      <PrivateRoute path="/attendance" component={CheckIn}  />
      <PrivateRoute path="/attendance/checkout" component={CheckOut}  />
      <PrivateRoute path="/attendance/wellbeing" component={Wellbeing}  />
      <Thankyou path="/attendance/thankyou" />
      <PrivateRoute path="/admin" component={AdminDashboard} roles={getProfile().roles} perform="admin-page:visit" />
      <PrivateRoute path="/admin/dashboard" component={AdminDashboard} roles={getProfile().roles} perform="admin-page:visit" />
      <PrivateRoute path="/admin/users" component={AdminUsers}  roles={getProfile().roles} perform="admin-page:visit" />
      <PrivateRoute path="/admin/organisations" component={AdminOrganisation}  roles={getProfile().roles} perform="admin-page:visit" />
      <PrivateRoute path="/admin/locations" component={AdminLocations}  roles={getProfile().roles} perform="admin-page:visit" />
      <PrivateRoute path="/admin/networks" component={AdminNetworks}  roles={getProfile().roles} perform="admin-page:visit" />
      <PrivateRoute path="/admin/test" component={AdminNetworks}  roles={getProfile().roles} perform="admin-page:test" />
      <Login path="/login" />
      <Logout path="/logout" />
      
      <IndexPage path="/" />
      
      <PrivateRoute path="/404" component={NotFoundPage} default />
    </Router>
  )
}

export default App

const Logout = () => {
  if(isAuthenticated()) {
    logout();
    return (<p>Redirecting to login...</p>);
  }
  navigate("/")
  return null;
}

const Login = () => {
  const location = useLocation();
  const searchParams = parse(location.search);
  if(isAuthenticated()) {
    if(searchParams.redirect) {
      navigate(searchParams.redirect);
      // return <Redirect to={searchParams.redirect} />
    } else {
      navigate("/app");
      // return <Redirect to={"/app"} />
    }
    return (
      <section className="text-center">
        <div className="leading-loose pt-8">
          Redirecting to login...
        </div>
      </section>
    );
  } else {
    loginPage(searchParams.redirect);
    return (
      <section className="text-center">
        <div className="leading-loose pt-8">
          Redirecting to login...
        </div>
      </section>
    );
  }
}
