import React from "react";
import PropTypes from 'prop-types';
import { getTokens } from "../../utils/auth";
import Input from "../../views/components/input";
import { asyncGetOptions } from '../../utils/helpers';
import Checkbox from "../../views/components/checkbox";
import Button from "../../views/components/button";
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
const axios = require('axios').default;

const initialState = { name: '', work_email: '', vocation_role: '', vocation: '', vocation_speciality: '', primary_organisation: '', organisations: [], primary_location: '', locations: [], home_vpn: false, home_laptop: false, updateData: {} };

class UserModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if update data is being passed through
    if (this.props.modalType === "update" && Object.entries(this.props.data).length > 0) {
      this.setState({ ...this.props.data }, () => this.updateSelectState())
    }
  }

  updateSelectState() {
    // locations
    {
      let currentValues = [];
      let primaryLocationID = this.state.primary_location?._id;

      const selectedIDs = this.state.locations;
      asyncGetOptions([], "locations")
        .then((locations) => {
          locations.forEach(location => {
            selectedIDs.forEach(ID => {
              if (location.value === ID._id) {
                currentValues.push({ label: location.label, value: ID._id })
              }
              if (location.value === primaryLocationID) {
                this.setState({ primary_location: { label: location.label, value: primaryLocationID } })
              }
            })
          })
          this.setState({ locations: currentValues }, () => console.log(this.state));
        })
    }

    // organisations
    {
      let currentValues = [];
      let primaryOrganisationID = this.state.primary_organisation?._id;

      const selectedIDs = this.state.organisations;
      asyncGetOptions([], "organisations")
        .then((organisations) => {
          organisations.forEach(organisation => {
            selectedIDs.forEach(ID => {
              if (organisation.value === ID._id) {
                currentValues.push({ label: organisation.label, value: ID._id })
              }
              if (organisation.value === primaryOrganisationID) {
                this.setState({ primary_organisation: { label: organisation.label, value: primaryOrganisationID } })
              }
            })
          })
          this.setState({ organisations: currentValues }, () => console.log(this.state));
        })
    }

    // vocation roles
    let role = this.state.vocation_role;
    if (role) {
      const roleObj = { 'label': role, 'value': role };
      this.setState({ vocation_role: roleObj });
    }

    // vocation
    let vocation = this.state.vocation;
    if (vocation) {
      const vocationObj = { 'label': vocation, 'value': vocation };
      this.setState({ vocation: vocationObj });
    }

    // vocation speciality
    let vocation_speciality = this.state.vocation_speciality;
    if (vocation_speciality) {
      const vocation_specialityObj = { 'label': vocation_speciality, 'value': vocation_speciality };
      this.setState({ vocation_speciality: vocation_specialityObj });
    }

  }

  randomString(len = 16) {
    var length = len;
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*()_+~`|}{[]\\:;?><,./-=';
    var password = "";
    var character = "";
    while (password.length < length) {
      var entity1 = Math.ceil(string.length * Math.random() * Math.random());
      var entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      var entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
      var hold = string.charAt(entity1);
      hold = (password.length % 2 == 0) ? (hold.toUpperCase()) : (hold);
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');
    return password.substr(0, len);
  }

  asyncAllRoles = (inputValue, callback) => {
    let args = {
      method: 'get',
      url: process.env.ETHO_API + '/ref/vocations',
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    }
    if (inputValue && inputValue.length > 0) {
      args.params = { query: '{"name":{"$options": "i", "$regex":"' + inputValue + '"}}' }
    }
    axios(args)
      .then((res) => {
        const data = res.data.map((el) => { return { value: el, label: el } });
        return callback(data)
      })
  };

  asyncGetVocation = (inputValue, callback) => {
    if (!this.state.vocation_role || !this.state.vocation_role.value) {
      return callback();
    }
    let args = {
      method: 'get',
      url: process.env.ETHO_API + '/ref/vocations/' + this.state.vocation_role.value,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    }
    if (inputValue && inputValue.length > 0) {
      args.params = { query: '{"name":{"$options": "i", "$regex":"' + inputValue + '"}}' }
    }
    axios(args)
      .then((res) => {
        const data = res.data.map((el) => { return { value: el, label: el } });
        return callback(data)
      })
  };

  asyncGetSpeciality = (inputValue, callback) => {
    if (!this.state.vocation_role || !this.state.vocation_role.value || !this.state.vocation || !this.state.vocation.value) {
      return callback();
    }
    console.log(this.state.vocation_role.value)
    console.log(this.state.vocation.value)
    let args = {
      method: 'get',
      url: process.env.ETHO_API + '/ref/vocations/' + this.state.vocation_role.value + '/' + this.state.vocation.value,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    }
    if (inputValue && inputValue.length > 0) {
      args.params = { query: '{"name":{"$options": "i", "$regex":"' + inputValue + '"}}' }
    }
    axios(args)
      .then((res) => {
        const data = (res.data) ? res.data.map((el) => { return { value: el, label: el } }) : [];
        return callback(data)
      })
  };


  asyncOrganisations(inputValue, callback) {
    asyncGetOptions(inputValue, 'organisations')
      .then((res) => {
        return callback(res);
      });
  }

  asyncLocations(inputValue, callback) {
    asyncGetOptions(inputValue, 'locations')
      .then((res) => {
        return callback(res);
      });
  }

  handleAsyncChange(key, event) {
    const label = event.label;
    const value = event.value;
    this.setState({ [key]: { label, value } })
    if (key === "vocation_role") {
      this.setState({ 'vocation': '', 'vocation_speciality': '' });
    }
    else if (key === "vocation") {
      this.setState({ 'vocation_speciality': '' });
    }

  }

  handleChange(key, value) {
    this.setState({ [key]: value }, () => console.log(this.state));

    if (key === "primary_organisation") {
      let orgKeys = this.state.organisations.map(o => o.value);
      if(!(orgKeys || []).includes(value.value)) {
        let organisations = this.state.organisations;
        organisations.push(value);
        this.setState({ organisations: organisations })
      }
    } 
    else if (key === "primary_location") {
      let locationKeys = this.state.locations.map(o => o.value);
      if(!(locationKeys || []).includes(value.value))
      {
        let locations = this.state.locations;
        locations.push(value);
        this.setState({ locations: locations })
      }
    }
  }

  handlePasswordReset = () => {
    console.log(this.props.data.auth0_id);
    axios({
      method: 'post',
      url: process.env.ETHO_API + '/users/reset_password',
      data: { auth0Id: this.props.data.auth0_id},
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    }).then(() => {
      toast.success("Password reset sent")
    }).catch((err) => {
      toast.error(err.response.data.message);
    })
  }

  handleDelete = () => {
    axios({
      method: 'get',
      url: process.env.ETHO_API + `/members?filter[auth0_id]=${this.props.data.auth0_id}`,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    }).then((res) => {
      axios({
        method: 'delete',
        url: process.env.ETHO_API + `/members/${this.props.data._id}`,
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      }).then(() => {
        toast.success(`Member account ${this.props.data.work_email} deleted`);
      }).catch((err) => {
        toast.error(err.response.data.message);
      });
      // delete auth0 user if there is only one user with the auth0 account
      if(res.data && res.data.length === 1) {
        axios({
          method: 'delete',
          url: process.env.ETHO_API + `/users/${this.props.data.auth0_id}`,
          headers: { "Authorization": "Bearer " + getTokens().accessToken }
        }).then(() => {
          toast.success(`Auth0 account ${this.props.data.work_email} deleted`);
        }).catch((err) => {
          toast.error(err.response.data.message);
        });
      }
      // remove from data array
      this.props.deleteCallback(this.props.data._id);
    }).catch((err) => {
      toast.error(err.response.data.message);
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, work_email, home_vpn, home_laptop } = this.state;
    console.log(this.state.vocation_role);
    const vocation_role = this.state.vocation_role ? this.state.vocation_role.value : '';
    const vocation = this.state.vocation ? this.state.vocation.value : '';
    const vocation_speciality = this.state.vocation_speciality ? this.state.vocation_speciality.value : '';

    const organisations = this.state.organisations ? this.state.organisations.map((el) => { return el.value }) : [];
    const locations = this.state.locations ? this.state.locations.map((el) => { return el.value }) : [];
    const primary_organisation = this.state.primary_organisation.value;
    const primary_location = this.state.primary_location?.value;

    const formData = {
      name,
      work_email,
      vocation_role,
      vocation,
      vocation_speciality,
      primary_organisation,
      organisations,
      primary_location,
      locations,
      home_vpn,
      home_laptop,
      password: this.randomString()
    };

    if (this.props.modalType === "add") {
      axios({
        method: 'post',
        url: process.env.ETHO_API + '/users',
        data: { email: formData.work_email, name: formData.name, password: formData.password },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
        .then((res) => {
          axios({
            method: 'post',
            url: process.env.ETHO_API + '/members',
            data: { ...formData, auth0_id: res.data.user_id },
            headers: { "Authorization": "Bearer " + getTokens().accessToken }
          })
            .then((member) => {
              console.log(member);
              toast.success("Successfully updated user")
              setTimeout(() => { window.location.reload(); }, 1500);
            })
            .catch((err) => {
              toast.error(err.response.data.message);
            })
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
    }
    else if (this.props.modalType === "update") {
      axios({
        method: 'patch',
        url: process.env.ETHO_API + '/members/' + this.state._id,
        data: { ...formData },
        headers: { "Authorization": "Bearer " + getTokens().accessToken }
      })
        .then((member) => {
          console.log(member);
          toast.success("Successfully updated user")
          setTimeout(() => { window.location.reload(); }, 1500);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="px-5 py-4 text-center text-xl">Add User</div>
        <hr className="mb-5" />
        <div className="px-8">
          <Input type="text" placeholder="e.g. John Smith" labelText="Name" onChange={(event) => this.handleChange("name", event.target.value)} value={this.state.name} />
          <Input type="email" placeholder="e.g. name@myemail.com" labelText="Email" onChange={(event) => this.handleChange("work_email", event.target.value)} value={this.state.work_email} />
          <AsyncSelect key={"role"} defaultOptions loadOptions={this.asyncAllRoles} className="mb-2" onChange={(event) => this.handleAsyncChange("vocation_role", event)} value={this.state.vocation_role} placeholder={"Select Role"} />
          <AsyncSelect key={"vocation" + JSON.stringify(this.state.vocation_role)} defaultOptions loadOptions={this.asyncGetVocation} className="mb-2" onChange={(event) => this.handleAsyncChange("vocation", event)} value={this.state.vocation} placeholder={"Select Vocation"} />
          <AsyncSelect key={"speciality" + JSON.stringify(this.state.vocation)} defaultOptions loadOptions={this.asyncGetSpeciality} className="mb-2" onChange={(event) => this.handleAsyncChange("vocation_speciality", event)} value={this.state.vocation_speciality} placeholder={"Select Speciality"} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8">
          <label className="block mt-4">Primary Organisation</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncOrganisations} onChange={(event) => this.handleChange("primary_organisation", event)} value={this.state.primary_organisation} />

          <label className="block mt-4">Linked Organisations</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncOrganisations} onChange={(event) => this.handleChange("organisations", event)} value={this.state.organisations} isMulti={true} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8">
          <label className="block mt-4">Primary Location</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncLocations} onChange={(event) => this.handleChange("primary_location", event)} value={this.state.primary_location} />

          <label className="block mt-4">Linked Locations</label>
          <AsyncSelect defaultOptions loadOptions={this.asyncLocations} onChange={(event) => this.handleChange("locations", event.value)} value={this.state.locations} isMulti={true} />
        </div>

        <hr className="mt-6 mb-5" />
        <div className="px-8 pb-4">
          <label className="block">Home working options:</label>
          <Checkbox label="VPN" name="home_vpn" onChange={(event) => this.handleChange("home_vpn", event.target.checked)} checked={this.state.home_vpn} />
          <Checkbox label="Laptop" name="home_laptop" onChange={(event) => this.handleChange("home_laptop", event.target.checked)} checked={this.state.home_laptop} />
        </div>

        <div className="px-8 pb-6">
          <Button styleType="main">Confirm</Button>
        </div>
        {(this.props.modalType === "update") ? (
          <>
            <div className="px-8 pb-6 text-center">
              <a href="#" onClick={(event) => {event.preventDefault(); this.handlePasswordReset();}}>Reset Password</a>
            </div>
            <div className="px-8 pb-6 text-center">
              <a href="#" onClick={(event) => {event.preventDefault(); this.handleDelete();}}>Delete Member</a>
            </div>
          </>
        ) : (<></>)}
      </form>
    )
  }
}

UserModalContent.propTypes = {
  modalType: PropTypes.string,
  data: PropTypes.object,
  deleteCallback: PropTypes.func
}

export default UserModalContent;
