import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppLayout from "../../views/containers/appLayout";
import AppLayoutContainer from "../../views/containers/AppLayoutContainer";
import Title from "../../views/components/title";
import Layout from "../../views/components/layout";
import Button from "../../views/components/button";
import { getTokens } from "../../utils/auth";
import { navigate } from "@reach/router";
const axios = require('axios').default;

import IconAtWork from "../../images/icon-at-work.svg";
import IconAtHome from "../../images/icon-home-working.svg";

const CheckOut = () => {
  const [userData, setUserData] = useState();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    loadData();
    setInterval(loadData, 300*1000);
  }, []);

  const loadData = () => {
    // get todays date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = mm + '-' + dd + '-' + yyyy;

    // GET USER - get user attendance
    console.log("Exec request...");
    let requestUrl = `${process.env.ETHO_API}/me/attendances?filter[type][$in]=["location","organisation"]&filter[check_out_time][$eq]=null&filter[check_in_time][$gte]=${today}&populate=location&populate=organisation&populate=member&sort[check_in_time]=-1&page[limit]=1`;
    console.log(requestUrl);
    axios({
      method: 'get',
      url: requestUrl ,
      headers: { "Authorization": "Bearer " + getTokens().accessToken }
    })
      .then((res) => {
        console.log(res.data);
        // if null or checkout time set -> redirect to check-in
        if (!res.data || res.data.length == 0 || res.data[0].check_out_time) {
          navigate("/app/attendance")
          return;
        }
        // if not working 
        if (res.data[0].type != 'location' && res.data[0].type != 'organisation') {
          navigate("/app/attendance/wellbeing");
          return;
        }

        setUserData(res.data[0]);
        // ACTIVE TEAM - get attendances today
        let organisation = res.data[0].organisation._id;
        axios({
          method: 'get',
          url: `${process.env.ETHO_API}/attendances?filter[type][$in]=["location","organisation"]&filter[check_out_time][$eq]=null&filter[check_in_time][$gte]=${today}&filter[organisation]=${organisation}&populate=organisation&populate=location&populate=member&sort[check_in_time]=-1`,
          headers: { "Authorization": "Bearer " + getTokens().accessToken }
        })
          .then((res) => {
            // only show latest attendance per user
            let attendances = [];
            res.data.forEach((attendance) => {
              let existingUser = attendances.filter((cur) => {
                return cur.member._id === attendance.member._id;
              });
              if (existingUser && existingUser.length > 0) {
                if (existingUser[0].check_in_time < attendance.check_in_time) {
                  attendances = attendances.reduce(u => {
                    u.member._id != attendance.member._id;
                  })
                  attendances.push(attendance);
                }
              } else {
                attendances.push(attendance);
              }
            })
            console.log(attendances);
            setUsers(attendances);
          })
      })
      .catch((err) => {
        console.log(err)
        throw err;
      })
  }

  let locationName = (userData && userData.location) ? userData.location.name : "";
  //let orgName = (userData && userData.organisation) ? userData.organisation.name : "";
  let name = userData ? userData.member.name : "";

  return (
    <Layout>
      <AppLayout>
        <Title>{name}</Title>
        <AppLayoutContainer>
          <div className="text-center">
            <span className="inline font-bold">You are checked in at: </span>
            <span className="text-xl">{locationName ? locationName : "Home Working"}</span>
            <div className="block w-full sm:w-72 mx-auto mt-4">
              <Button styleType="checkOut" onClick={() => navigate('/app/attendance/wellbeing/')}>Check Out</Button>
            </div>
          </div>
        </AppLayoutContainer>

        <hr className="mt-8 mb-5" />

        <AppLayoutContainer>
          <div className="container mx-auto">
            <h2 className="h2 text-center mb-6">Your Active Team</h2>
            <div className="flex flex-row flex-wrap -mx-4">
              {users.map((user, i) => (
                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4" key={i}>
                  <div className="bg-gray-100 px-5 py-4">
                    <div className="flex flex-row flex-wrap -mx-2">
                      <div className="flex items-start pr-3 mr-1 border-r">
                        {user.type === "location" && (
                          <img alt="available" src={IconAtWork} />
                        )}
                        {user.type === "organisation" && (
                          <img alt="available" src={IconAtHome} />
                        )}
                      </div>
                      <div className="flex-1 px-2">
                        <div className="leading-none mb-1">{user.member.name}</div>
                        {user.member.vocation && (
                          <div className="text-sm font-bold">{user.member.vocation}{user.member.vocation_speciality ? ', ' + user.member.vocation_speciality : ''} </div>
                        )}
                        <div className="text-sm">{user.location && user.location.name ? user.location.name : "Home Working"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AppLayoutContainer>
      </AppLayout>
    </Layout >
  );
}

CheckOut.propTypes = {
  location: PropTypes.object
};

export default CheckOut;
