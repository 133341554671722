import React from "react";
import PropTypes from "prop-types";

class AppLayout extends React.Component {
  render() {
    return (      
        <div className="container px-8 block pt-12 mx-auto">
          <div>
              {this.props.children}
          </div>
        </div>
    );
  }
}

AppLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default AppLayout;
